import { readonly } from 'vue';
import { defineStore } from 'pinia';

// types
import type { IpInfo } from '~/types/api-responses';
import type { AppOverlayProps } from '~/components/redesign/AppOverlay.vue';

export const useCommonStore = defineStore('common', () => {
  const ipInfo = ref<null | IpInfo>(null);

  const requestIpInfo = async () => {
    const { public: { apiIpInfo } } = useRuntimeConfig();

    return useFetch<IpInfo>(apiIpInfo, {
      method: 'GET',
      lazy: true,
      server: false,
    });
  };

  const setIpInfo = (info: IpInfo | null) => {
    ipInfo.value = info;
  };

  const isLoading = ref(false);
  const setLoading = (value: boolean) => {
    isLoading.value = value;
  };

  const overlay = ref<AppOverlayProps>({ visible: false });
  const setOverlay = (props: AppOverlayProps) => {
    overlay.value = { ...overlay.value, ...props };
  };

  watch(overlay, ({ visible }) => {
    document.body.classList[visible ? 'add' : 'remove']('no-scroll');
  });

  return {
    ipInfo: readonly(ipInfo),
    setIpInfo,
    requestIpInfo,

    isLoading: readonly(isLoading),
    setLoading,

    overlay: readonly(overlay),
    setOverlay,
  };
});
