<template>
  <header data-component-name="TheHeader">
    <div class="wrapper">
      <AppLogo />
      <MenuDesktop />

      <div class="right">
        <LanguageSwitcherDesktop />

        <ButtonLink
          :to="localePathWithSlash(PATHS.HOW_TO_BUY.path)"
          fill="outline"
          accent="orange"
        >
          {{ $t('How_to_Buy') }}
        </ButtonLink>

        <ButtonLink
          :to="localePathWithSlash(PATHS.REQUEST_DEMO.path)"
          fill="solid"
          accent="orange"
        >
          {{ $t('Request_a_demo') }}
          <SvgArrowForward />
        </ButtonLink>

        <slot name="right" />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
// constants
import { PATHS } from '~/constants/nav-items';

// composable
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

// components
import AppLogo from '~/components/redesign/AppLogo.vue';
import ButtonLink from "~/components/redesign/ButtonLink.vue";
import MenuDesktop from '~/components/redesign/Menus/MenuDesktop.vue';
import LanguageSwitcherDesktop from '~/components/redesign/LanguageSwitcherDesktop.vue';

const localePathWithSlash = useLocalePathWithSlash();
</script>

<style scoped lang="scss">
@import "$/mixins/flex";
@import "$/mixins/common";
@import "$/mixins/typography";
@import "$/functions/token";
@import "$/variables/shadows";

[data-component-name="TheHeader"] {
  box-shadow: map-get($shadows, 'Elevation 1');
  background-color: token('surface');

  .wrapper {
    @include wrapper(calc(100% - 10rem));
    @include flex-stretch-sb;

    @include tablet {
      padding: 1rem 0;
      @include wrapper(calc(100% - 4rem));
    }

    @include mobile {
      padding: 0.61rem 0;
      @include wrapper(calc(100% - 2rem));
    }

    [data-component-name="AppLogo"] {
      @include flex-center-center;

      &:deep(svg) {
        @include mobile {
          @include fixed-width(7rem);
          @include fixed-height(2.27025rem);
        }
      }
    }

    [data-component-name="MenuDesktop"] {
      @include tablet {
        display: none;
      }
    }

    .right {
      @include flex-center-sb;
      gap: 1rem;
      min-height: 100%;

      [data-component-name="LanguageSwitcherDesktop"] {
        height: 100%;

        @include tablet {
          display: none;
        }
      }

      [data-component-name="ButtonLink"] {
        @include mobile {
          display: none;
        }
      }
    }
  }
}
</style>
