import { default as _91_46_46_46slug_93uJ6S9oMrv3Meta } from "/app/pages/3-reasons-ootbi-is-best-for-veeam/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93QhmJPQB188Meta } from "/app/pages/3-reasons-ootbi-is-best-storage-for-veeam/[...slug].vue?macro=true";
import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as _91slug_93WB3MvnWjfwMeta } from "/app/pages/author/[slug].vue?macro=true";
import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as careersb3LI3USwU5Meta } from "/app/pages/careers.vue?macro=true";
import { default as channel_45team_45contacts8x8WPAHibKMeta } from "/app/pages/channel-team-contacts.vue?macro=true";
import { default as contactsHSHl3sOssUMeta } from "/app/pages/contacts.vue?macro=true";
import { default as _91_46_46_46slug_93j8Z2o7lRWdMeta } from "/app/pages/demo-ootbi/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_931XuX7ez4exMeta } from "/app/pages/gift-campaign/[...slug].vue?macro=true";
import { default as _91slug_937AZio3SPz5Meta } from "/app/pages/guides/[category]/[slug].vue?macro=true";
import { default as enterprise_45data_45storage_45for_45backupsWBXsr3mk7WMeta } from "/app/pages/guides/data-storage/enterprise-data-storage-for-backups.vue?macro=true";
import { default as on_45premises_45object_45storageVB4cyrHS2HMeta } from "/app/pages/guides/data-storage/on-premises-object-storage.vue?macro=true";
import { default as immutable_45storagex1pOvfeeF4Meta } from "/app/pages/guides/immutability/immutable-storage.vue?macro=true";
import { default as index2KUukhUoY3Meta } from "/app/pages/guides/index.vue?macro=true";
import { default as _91slug_93zLa3eihXzhMeta } from "/app/pages/help/[slug].vue?macro=true";
import { default as how_45to_45buy_45ootbicMLExsLEAYMeta } from "/app/pages/how-to-buy-ootbi.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as installation_45_91id_93xEf9w4r8l1Meta } from "/app/pages/installation-[id].vue?macro=true";
import { default as _91slug_93TzhD3BEeTvMeta } from "/app/pages/legal/[slug].vue?macro=true";
import { default as index8NBB3chSIOMeta } from "/app/pages/legal/index.vue?macro=true";
import { default as _91category_93iZet2Yzxp8Meta } from "/app/pages/newsroom/[category].vue?macro=true";
import { default as indexw3HcZQJBSLMeta } from "/app/pages/newsroom/events/index.vue?macro=true";
import { default as indexgyJRKObFgMMeta } from "/app/pages/newsroom/index.vue?macro=true";
import { default as indexxUBMmAUGKoMeta } from "/app/pages/newsroom/product-reviews/index.vue?macro=true";
import { default as newsroom9c617uIOskMeta } from "/app/pages/newsroom.vue?macro=true";
import { default as object_45storage2RYGi0kPuzMeta } from "/app/pages/object-storage.vue?macro=true";
import { default as _91_46_46_46slug_93LcUFkgAq7lMeta } from "/app/pages/ootbi-192tb-gift-campaign/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93cIj3LN2aY2Meta } from "/app/pages/ootbi-best-storage-for-veeam/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93XoawkGWKlmMeta } from "/app/pages/ootbi-welcome-kit/[...slug].vue?macro=true";
import { default as Veeamon_Tour_Sao_Paulo_AUG_29aDHCfcdqspMeta } from "/app/pages/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29.vue?macro=true";
import { default as partner_45rewardsT2Adcz84u9Meta } from "/app/pages/partner-rewards.vue?macro=true";
import { default as partnersEg1L314k67Meta } from "/app/pages/partners.vue?macro=true";
import { default as request_45live_45demonXSANcgMVgMeta } from "/app/pages/request-live-demo.vue?macro=true";
import { default as _91category_93rhdUO8wqGQMeta } from "/app/pages/resources/[category].vue?macro=true";
import { default as index6TJluURa3EMeta } from "/app/pages/resources/index.vue?macro=true";
import { default as resourceszAaIsuKYxcMeta } from "/app/pages/resources.vue?macro=true";
import { default as see_45you_45soon0C9vOdD16QMeta } from "/app/pages/see-you-soon.vue?macro=true";
import { default as supportPKo8CaxFTuMeta } from "/app/pages/support.vue?macro=true";
import { default as upcoming_45partner_45webinard4DsYdgFyuMeta } from "/app/pages/upcoming-partner-webinar.vue?macro=true";
import { default as _91_46_46_46slug_93Wh4YAnZy5SMeta } from "/app/pages/virtual-storage-appliance/[...slug].vue?macro=true";
import { default as setup_45file_45downloadD5FI3VUytXMeta } from "/app/pages/virtual-storage-appliance/setup-file-download.vue?macro=true";
import { default as _91_46_46_46slug_93TliyBQZ9tfMeta } from "/app/pages/welcome-192tb-ootbi/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93zCgw7Pfr41Meta } from "/app/pages/zero-trust-data-resilience/[...slug].vue?macro=true";
import { default as _91slug_933lvrbksdalMeta } from "/app/pages/newsroom/events/[slug].vue?macro=true";
import { default as indexSGWwDi4qAwMeta } from "/app/pages/newsroom/media-kit/index.vue?macro=true";
import { default as _91slug_93Jt6jHIaURwMeta } from "/app/pages/newsroom/press-releases/[slug].vue?macro=true";
import { default as _91slug_93ZPyHXgvsjLMeta } from "/app/pages/resources/analyst-reports/[slug].vue?macro=true";
import { default as _91slug_93m6SD0DdhgvMeta } from "/app/pages/resources/case-studies/[slug].vue?macro=true";
import { default as data_45sheet_45ootbi_45the_45best_45storage_45for_45veeamYJkOtEYgjAMeta } from "/app/pages/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "3-reasons-ootbi-is-best-for-veeam-slug___en",
    path: "/3-reasons-ootbi-is-best-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93uJ6S9oMrv3Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-for-veeam/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "3-reasons-ootbi-is-best-for-veeam-slug___de",
    path: "/de/3-reasons-ootbi-is-best-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93uJ6S9oMrv3Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-for-veeam/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "3-reasons-ootbi-is-best-for-veeam-slug___fr",
    path: "/fr/3-reasons-ootbi-is-best-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93uJ6S9oMrv3Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-for-veeam/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "3-reasons-ootbi-is-best-for-veeam-slug___es",
    path: "/es/3-reasons-ootbi-is-best-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93uJ6S9oMrv3Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-for-veeam/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "3-reasons-ootbi-is-best-storage-for-veeam-slug___en",
    path: "/3-reasons-ootbi-is-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93QhmJPQB188Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-storage-for-veeam/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "3-reasons-ootbi-is-best-storage-for-veeam-slug___de",
    path: "/de/3-reasons-ootbi-is-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93QhmJPQB188Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-storage-for-veeam/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "3-reasons-ootbi-is-best-storage-for-veeam-slug___fr",
    path: "/fr/3-reasons-ootbi-is-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93QhmJPQB188Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-storage-for-veeam/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "3-reasons-ootbi-is-best-storage-for-veeam-slug___es",
    path: "/es/3-reasons-ootbi-is-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93QhmJPQB188Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-storage-for-veeam/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/about",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___de",
    path: "/de/about",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___fr",
    path: "/fr/about",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___es",
    path: "/es/about",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "author-slug___en",
    path: "/author/:slug()",
    component: () => import("/app/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "author-slug___de",
    path: "/de/author/:slug()",
    component: () => import("/app/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "author-slug___fr",
    path: "/fr/author/:slug()",
    component: () => import("/app/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "author-slug___es",
    path: "/es/author/:slug()",
    component: () => import("/app/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___en",
    path: "/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___de",
    path: "/de/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___es",
    path: "/es/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___de",
    path: "/de/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "careers___en",
    path: "/careers",
    component: () => import("/app/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "careers___de",
    path: "/de/careers",
    component: () => import("/app/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "careers___fr",
    path: "/fr/careers",
    component: () => import("/app/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "careers___es",
    path: "/es/careers",
    component: () => import("/app/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "channel-team-contacts___en",
    path: "/channel-team-contacts",
    component: () => import("/app/pages/channel-team-contacts.vue").then(m => m.default || m)
  },
  {
    name: "channel-team-contacts___de",
    path: "/de/channel-team-contacts",
    component: () => import("/app/pages/channel-team-contacts.vue").then(m => m.default || m)
  },
  {
    name: "channel-team-contacts___fr",
    path: "/fr/channel-team-contacts",
    component: () => import("/app/pages/channel-team-contacts.vue").then(m => m.default || m)
  },
  {
    name: "channel-team-contacts___es",
    path: "/es/channel-team-contacts",
    component: () => import("/app/pages/channel-team-contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___en",
    path: "/contacts",
    component: () => import("/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___de",
    path: "/de/contacts",
    component: () => import("/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___fr",
    path: "/fr/contacts",
    component: () => import("/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___es",
    path: "/es/contacts",
    component: () => import("/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "demo-ootbi-slug___en",
    path: "/demo-ootbi/:slug(.*)*",
    meta: _91_46_46_46slug_93j8Z2o7lRWdMeta || {},
    component: () => import("/app/pages/demo-ootbi/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "demo-ootbi-slug___de",
    path: "/de/demo-ootbi/:slug(.*)*",
    meta: _91_46_46_46slug_93j8Z2o7lRWdMeta || {},
    component: () => import("/app/pages/demo-ootbi/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "demo-ootbi-slug___fr",
    path: "/fr/demo-ootbi/:slug(.*)*",
    meta: _91_46_46_46slug_93j8Z2o7lRWdMeta || {},
    component: () => import("/app/pages/demo-ootbi/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "demo-ootbi-slug___es",
    path: "/es/demo-ootbi/:slug(.*)*",
    meta: _91_46_46_46slug_93j8Z2o7lRWdMeta || {},
    component: () => import("/app/pages/demo-ootbi/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "gift-campaign-slug___en",
    path: "/gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_931XuX7ez4exMeta || {},
    component: () => import("/app/pages/gift-campaign/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "gift-campaign-slug___de",
    path: "/de/gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_931XuX7ez4exMeta || {},
    component: () => import("/app/pages/gift-campaign/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "gift-campaign-slug___fr",
    path: "/fr/gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_931XuX7ez4exMeta || {},
    component: () => import("/app/pages/gift-campaign/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "gift-campaign-slug___es",
    path: "/es/gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_931XuX7ez4exMeta || {},
    component: () => import("/app/pages/gift-campaign/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "guides-category-slug___en",
    path: "/guides/:category()/:slug()",
    component: () => import("/app/pages/guides/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "guides-category-slug___de",
    path: "/de/guides/:category()/:slug()",
    component: () => import("/app/pages/guides/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "guides-category-slug___fr",
    path: "/fr/guides/:category()/:slug()",
    component: () => import("/app/pages/guides/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "guides-category-slug___es",
    path: "/es/guides/:category()/:slug()",
    component: () => import("/app/pages/guides/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "guides-data-storage-enterprise-data-storage-for-backups___en",
    path: "/guides/data-storage/enterprise-data-storage-for-backups",
    component: () => import("/app/pages/guides/data-storage/enterprise-data-storage-for-backups.vue").then(m => m.default || m)
  },
  {
    name: "guides-data-storage-enterprise-data-storage-for-backups___de",
    path: "/de/guides/data-storage/enterprise-data-storage-for-backups",
    component: () => import("/app/pages/guides/data-storage/enterprise-data-storage-for-backups.vue").then(m => m.default || m)
  },
  {
    name: "guides-data-storage-enterprise-data-storage-for-backups___fr",
    path: "/fr/guides/data-storage/enterprise-data-storage-for-backups",
    component: () => import("/app/pages/guides/data-storage/enterprise-data-storage-for-backups.vue").then(m => m.default || m)
  },
  {
    name: "guides-data-storage-enterprise-data-storage-for-backups___es",
    path: "/es/guides/data-storage/enterprise-data-storage-for-backups",
    component: () => import("/app/pages/guides/data-storage/enterprise-data-storage-for-backups.vue").then(m => m.default || m)
  },
  {
    name: "guides-data-storage-on-premises-object-storage___en",
    path: "/guides/data-storage/on-premises-object-storage",
    component: () => import("/app/pages/guides/data-storage/on-premises-object-storage.vue").then(m => m.default || m)
  },
  {
    name: "guides-data-storage-on-premises-object-storage___de",
    path: "/de/guides/data-storage/on-premises-object-storage",
    component: () => import("/app/pages/guides/data-storage/on-premises-object-storage.vue").then(m => m.default || m)
  },
  {
    name: "guides-data-storage-on-premises-object-storage___fr",
    path: "/fr/guides/data-storage/on-premises-object-storage",
    component: () => import("/app/pages/guides/data-storage/on-premises-object-storage.vue").then(m => m.default || m)
  },
  {
    name: "guides-data-storage-on-premises-object-storage___es",
    path: "/es/guides/data-storage/on-premises-object-storage",
    component: () => import("/app/pages/guides/data-storage/on-premises-object-storage.vue").then(m => m.default || m)
  },
  {
    name: "guides-immutability-immutable-storage___en",
    path: "/guides/immutability/immutable-storage",
    component: () => import("/app/pages/guides/immutability/immutable-storage.vue").then(m => m.default || m)
  },
  {
    name: "guides-immutability-immutable-storage___de",
    path: "/de/guides/immutability/immutable-storage",
    component: () => import("/app/pages/guides/immutability/immutable-storage.vue").then(m => m.default || m)
  },
  {
    name: "guides-immutability-immutable-storage___fr",
    path: "/fr/guides/immutability/immutable-storage",
    component: () => import("/app/pages/guides/immutability/immutable-storage.vue").then(m => m.default || m)
  },
  {
    name: "guides-immutability-immutable-storage___es",
    path: "/es/guides/immutability/immutable-storage",
    component: () => import("/app/pages/guides/immutability/immutable-storage.vue").then(m => m.default || m)
  },
  {
    name: "guides___en",
    path: "/guides",
    component: () => import("/app/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "guides___de",
    path: "/de/guides",
    component: () => import("/app/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "guides___fr",
    path: "/fr/guides",
    component: () => import("/app/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "guides___es",
    path: "/es/guides",
    component: () => import("/app/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "help-slug___en",
    path: "/help/:slug()",
    meta: _91slug_93zLa3eihXzhMeta || {},
    component: () => import("/app/pages/help/[slug].vue").then(m => m.default || m)
  },
  {
    name: "help-slug___de",
    path: "/de/help/:slug()",
    meta: _91slug_93zLa3eihXzhMeta || {},
    component: () => import("/app/pages/help/[slug].vue").then(m => m.default || m)
  },
  {
    name: "help-slug___fr",
    path: "/fr/help/:slug()",
    meta: _91slug_93zLa3eihXzhMeta || {},
    component: () => import("/app/pages/help/[slug].vue").then(m => m.default || m)
  },
  {
    name: "help-slug___es",
    path: "/es/help/:slug()",
    meta: _91slug_93zLa3eihXzhMeta || {},
    component: () => import("/app/pages/help/[slug].vue").then(m => m.default || m)
  },
  {
    name: "how-to-buy-ootbi___en",
    path: "/how-to-buy-ootbi",
    component: () => import("/app/pages/how-to-buy-ootbi.vue").then(m => m.default || m)
  },
  {
    name: "how-to-buy-ootbi___de",
    path: "/de/how-to-buy-ootbi",
    component: () => import("/app/pages/how-to-buy-ootbi.vue").then(m => m.default || m)
  },
  {
    name: "how-to-buy-ootbi___fr",
    path: "/fr/how-to-buy-ootbi",
    component: () => import("/app/pages/how-to-buy-ootbi.vue").then(m => m.default || m)
  },
  {
    name: "how-to-buy-ootbi___es",
    path: "/es/how-to-buy-ootbi",
    component: () => import("/app/pages/how-to-buy-ootbi.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "installation-id___en",
    path: "/installation-:id()",
    component: () => import("/app/pages/installation-[id].vue").then(m => m.default || m)
  },
  {
    name: "installation-id___de",
    path: "/de/installation-:id()",
    component: () => import("/app/pages/installation-[id].vue").then(m => m.default || m)
  },
  {
    name: "installation-id___fr",
    path: "/fr/installation-:id()",
    component: () => import("/app/pages/installation-[id].vue").then(m => m.default || m)
  },
  {
    name: "installation-id___es",
    path: "/es/installation-:id()",
    component: () => import("/app/pages/installation-[id].vue").then(m => m.default || m)
  },
  {
    name: "legal-slug___en",
    path: "/legal/:slug()",
    component: () => import("/app/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: "legal-slug___de",
    path: "/de/legal/:slug()",
    component: () => import("/app/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: "legal-slug___fr",
    path: "/fr/legal/:slug()",
    component: () => import("/app/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: "legal-slug___es",
    path: "/es/legal/:slug()",
    component: () => import("/app/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: "legal___en",
    path: "/legal",
    component: () => import("/app/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: "legal___de",
    path: "/de/legal",
    component: () => import("/app/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: "legal___fr",
    path: "/fr/legal",
    component: () => import("/app/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: "legal___es",
    path: "/es/legal",
    component: () => import("/app/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: newsroom9c617uIOskMeta?.name,
    path: "/newsroom",
    component: () => import("/app/pages/newsroom.vue").then(m => m.default || m),
    children: [
  {
    name: "newsroom-category___en",
    path: ":category()",
    component: () => import("/app/pages/newsroom/[category].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-events___en",
    path: "events",
    component: () => import("/app/pages/newsroom/events/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___en",
    path: "",
    component: () => import("/app/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-product-reviews___en",
    path: "product-reviews",
    component: () => import("/app/pages/newsroom/product-reviews/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsroom9c617uIOskMeta?.name,
    path: "/de/newsroom",
    component: () => import("/app/pages/newsroom.vue").then(m => m.default || m),
    children: [
  {
    name: "newsroom-category___de",
    path: ":category()",
    component: () => import("/app/pages/newsroom/[category].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-events___de",
    path: "events",
    component: () => import("/app/pages/newsroom/events/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___de",
    path: "",
    component: () => import("/app/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-product-reviews___de",
    path: "product-reviews",
    component: () => import("/app/pages/newsroom/product-reviews/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsroom9c617uIOskMeta?.name,
    path: "/fr/newsroom",
    component: () => import("/app/pages/newsroom.vue").then(m => m.default || m),
    children: [
  {
    name: "newsroom-category___fr",
    path: ":category()",
    component: () => import("/app/pages/newsroom/[category].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-events___fr",
    path: "events",
    component: () => import("/app/pages/newsroom/events/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___fr",
    path: "",
    component: () => import("/app/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-product-reviews___fr",
    path: "product-reviews",
    component: () => import("/app/pages/newsroom/product-reviews/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsroom9c617uIOskMeta?.name,
    path: "/es/newsroom",
    component: () => import("/app/pages/newsroom.vue").then(m => m.default || m),
    children: [
  {
    name: "newsroom-category___es",
    path: ":category()",
    component: () => import("/app/pages/newsroom/[category].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-events___es",
    path: "events",
    component: () => import("/app/pages/newsroom/events/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom___es",
    path: "",
    component: () => import("/app/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-product-reviews___es",
    path: "product-reviews",
    component: () => import("/app/pages/newsroom/product-reviews/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "object-storage___en",
    path: "/object-storage",
    component: () => import("/app/pages/object-storage.vue").then(m => m.default || m)
  },
  {
    name: "object-storage___de",
    path: "/de/object-storage",
    component: () => import("/app/pages/object-storage.vue").then(m => m.default || m)
  },
  {
    name: "object-storage___fr",
    path: "/fr/object-storage",
    component: () => import("/app/pages/object-storage.vue").then(m => m.default || m)
  },
  {
    name: "object-storage___es",
    path: "/es/object-storage",
    component: () => import("/app/pages/object-storage.vue").then(m => m.default || m)
  },
  {
    name: "ootbi-192tb-gift-campaign-slug___en",
    path: "/ootbi-192tb-gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_93LcUFkgAq7lMeta || {},
    component: () => import("/app/pages/ootbi-192tb-gift-campaign/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ootbi-192tb-gift-campaign-slug___de",
    path: "/de/ootbi-192tb-gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_93LcUFkgAq7lMeta || {},
    component: () => import("/app/pages/ootbi-192tb-gift-campaign/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ootbi-192tb-gift-campaign-slug___fr",
    path: "/fr/ootbi-192tb-gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_93LcUFkgAq7lMeta || {},
    component: () => import("/app/pages/ootbi-192tb-gift-campaign/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ootbi-192tb-gift-campaign-slug___es",
    path: "/es/ootbi-192tb-gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_93LcUFkgAq7lMeta || {},
    component: () => import("/app/pages/ootbi-192tb-gift-campaign/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ootbi-best-storage-for-veeam-slug___en",
    path: "/ootbi-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93cIj3LN2aY2Meta || {},
    component: () => import("/app/pages/ootbi-best-storage-for-veeam/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ootbi-best-storage-for-veeam-slug___de",
    path: "/de/ootbi-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93cIj3LN2aY2Meta || {},
    component: () => import("/app/pages/ootbi-best-storage-for-veeam/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ootbi-best-storage-for-veeam-slug___fr",
    path: "/fr/ootbi-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93cIj3LN2aY2Meta || {},
    component: () => import("/app/pages/ootbi-best-storage-for-veeam/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ootbi-best-storage-for-veeam-slug___es",
    path: "/es/ootbi-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93cIj3LN2aY2Meta || {},
    component: () => import("/app/pages/ootbi-best-storage-for-veeam/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ootbi-welcome-kit-slug___en",
    path: "/ootbi-welcome-kit/:slug(.*)*",
    meta: _91_46_46_46slug_93XoawkGWKlmMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ootbi-welcome-kit-slug___de",
    path: "/de/ootbi-welcome-kit/:slug(.*)*",
    meta: _91_46_46_46slug_93XoawkGWKlmMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ootbi-welcome-kit-slug___fr",
    path: "/fr/ootbi-welcome-kit/:slug(.*)*",
    meta: _91_46_46_46slug_93XoawkGWKlmMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ootbi-welcome-kit-slug___es",
    path: "/es/ootbi-welcome-kit/:slug(.*)*",
    meta: _91_46_46_46slug_93XoawkGWKlmMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Sao_Paulo_AUG_29___en",
    path: "/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29",
    meta: Veeamon_Tour_Sao_Paulo_AUG_29aDHCfcdqspMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29.vue").then(m => m.default || m)
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Sao_Paulo_AUG_29___de",
    path: "/de/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29",
    meta: Veeamon_Tour_Sao_Paulo_AUG_29aDHCfcdqspMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29.vue").then(m => m.default || m)
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Sao_Paulo_AUG_29___fr",
    path: "/fr/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29",
    meta: Veeamon_Tour_Sao_Paulo_AUG_29aDHCfcdqspMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29.vue").then(m => m.default || m)
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Sao_Paulo_AUG_29___es",
    path: "/es/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29",
    meta: Veeamon_Tour_Sao_Paulo_AUG_29aDHCfcdqspMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29.vue").then(m => m.default || m)
  },
  {
    name: "partner-rewards___en",
    path: "/partner-rewards",
    meta: partner_45rewardsT2Adcz84u9Meta || {},
    component: () => import("/app/pages/partner-rewards.vue").then(m => m.default || m)
  },
  {
    name: "partner-rewards___de",
    path: "/de/partner-rewards",
    meta: partner_45rewardsT2Adcz84u9Meta || {},
    component: () => import("/app/pages/partner-rewards.vue").then(m => m.default || m)
  },
  {
    name: "partner-rewards___fr",
    path: "/fr/partner-rewards",
    meta: partner_45rewardsT2Adcz84u9Meta || {},
    component: () => import("/app/pages/partner-rewards.vue").then(m => m.default || m)
  },
  {
    name: "partner-rewards___es",
    path: "/es/partner-rewards",
    meta: partner_45rewardsT2Adcz84u9Meta || {},
    component: () => import("/app/pages/partner-rewards.vue").then(m => m.default || m)
  },
  {
    name: "partners___en",
    path: "/partners",
    component: () => import("/app/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: "partners___de",
    path: "/de/partners",
    component: () => import("/app/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: "partners___fr",
    path: "/fr/partners",
    component: () => import("/app/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: "partners___es",
    path: "/es/partners",
    component: () => import("/app/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: "request-live-demo___en",
    path: "/request-live-demo",
    component: () => import("/app/pages/request-live-demo.vue").then(m => m.default || m)
  },
  {
    name: "request-live-demo___de",
    path: "/de/request-live-demo",
    component: () => import("/app/pages/request-live-demo.vue").then(m => m.default || m)
  },
  {
    name: "request-live-demo___fr",
    path: "/fr/request-live-demo",
    component: () => import("/app/pages/request-live-demo.vue").then(m => m.default || m)
  },
  {
    name: "request-live-demo___es",
    path: "/es/request-live-demo",
    component: () => import("/app/pages/request-live-demo.vue").then(m => m.default || m)
  },
  {
    name: resourceszAaIsuKYxcMeta?.name,
    path: "/resources",
    component: () => import("/app/pages/resources.vue").then(m => m.default || m),
    children: [
  {
    name: "resources-category___en",
    path: ":category()",
    component: () => import("/app/pages/resources/[category].vue").then(m => m.default || m)
  },
  {
    name: "resources___en",
    path: "",
    component: () => import("/app/pages/resources/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: resourceszAaIsuKYxcMeta?.name,
    path: "/de/resources",
    component: () => import("/app/pages/resources.vue").then(m => m.default || m),
    children: [
  {
    name: "resources-category___de",
    path: ":category()",
    component: () => import("/app/pages/resources/[category].vue").then(m => m.default || m)
  },
  {
    name: "resources___de",
    path: "",
    component: () => import("/app/pages/resources/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: resourceszAaIsuKYxcMeta?.name,
    path: "/fr/resources",
    component: () => import("/app/pages/resources.vue").then(m => m.default || m),
    children: [
  {
    name: "resources-category___fr",
    path: ":category()",
    component: () => import("/app/pages/resources/[category].vue").then(m => m.default || m)
  },
  {
    name: "resources___fr",
    path: "",
    component: () => import("/app/pages/resources/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: resourceszAaIsuKYxcMeta?.name,
    path: "/es/resources",
    component: () => import("/app/pages/resources.vue").then(m => m.default || m),
    children: [
  {
    name: "resources-category___es",
    path: ":category()",
    component: () => import("/app/pages/resources/[category].vue").then(m => m.default || m)
  },
  {
    name: "resources___es",
    path: "",
    component: () => import("/app/pages/resources/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "see-you-soon___en",
    path: "/see-you-soon",
    meta: see_45you_45soon0C9vOdD16QMeta || {},
    component: () => import("/app/pages/see-you-soon.vue").then(m => m.default || m)
  },
  {
    name: "see-you-soon___de",
    path: "/de/see-you-soon",
    meta: see_45you_45soon0C9vOdD16QMeta || {},
    component: () => import("/app/pages/see-you-soon.vue").then(m => m.default || m)
  },
  {
    name: "see-you-soon___fr",
    path: "/fr/see-you-soon",
    meta: see_45you_45soon0C9vOdD16QMeta || {},
    component: () => import("/app/pages/see-you-soon.vue").then(m => m.default || m)
  },
  {
    name: "see-you-soon___es",
    path: "/es/see-you-soon",
    meta: see_45you_45soon0C9vOdD16QMeta || {},
    component: () => import("/app/pages/see-you-soon.vue").then(m => m.default || m)
  },
  {
    name: "support___en",
    path: "/support",
    component: () => import("/app/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___de",
    path: "/de/support",
    component: () => import("/app/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___fr",
    path: "/fr/support",
    component: () => import("/app/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___es",
    path: "/es/support",
    component: () => import("/app/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "upcoming-partner-webinar___en",
    path: "/upcoming-partner-webinar",
    meta: upcoming_45partner_45webinard4DsYdgFyuMeta || {},
    component: () => import("/app/pages/upcoming-partner-webinar.vue").then(m => m.default || m)
  },
  {
    name: "upcoming-partner-webinar___de",
    path: "/de/upcoming-partner-webinar",
    meta: upcoming_45partner_45webinard4DsYdgFyuMeta || {},
    component: () => import("/app/pages/upcoming-partner-webinar.vue").then(m => m.default || m)
  },
  {
    name: "upcoming-partner-webinar___fr",
    path: "/fr/upcoming-partner-webinar",
    meta: upcoming_45partner_45webinard4DsYdgFyuMeta || {},
    component: () => import("/app/pages/upcoming-partner-webinar.vue").then(m => m.default || m)
  },
  {
    name: "upcoming-partner-webinar___es",
    path: "/es/upcoming-partner-webinar",
    meta: upcoming_45partner_45webinard4DsYdgFyuMeta || {},
    component: () => import("/app/pages/upcoming-partner-webinar.vue").then(m => m.default || m)
  },
  {
    name: "virtual-storage-appliance-slug___en",
    path: "/virtual-storage-appliance/:slug(.*)*",
    meta: _91_46_46_46slug_93Wh4YAnZy5SMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "virtual-storage-appliance-slug___de",
    path: "/de/virtual-storage-appliance/:slug(.*)*",
    meta: _91_46_46_46slug_93Wh4YAnZy5SMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "virtual-storage-appliance-slug___fr",
    path: "/fr/virtual-storage-appliance/:slug(.*)*",
    meta: _91_46_46_46slug_93Wh4YAnZy5SMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "virtual-storage-appliance-slug___es",
    path: "/es/virtual-storage-appliance/:slug(.*)*",
    meta: _91_46_46_46slug_93Wh4YAnZy5SMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "virtual-storage-appliance-setup-file-download___en",
    path: "/virtual-storage-appliance/setup-file-download",
    meta: setup_45file_45downloadD5FI3VUytXMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/setup-file-download.vue").then(m => m.default || m)
  },
  {
    name: "virtual-storage-appliance-setup-file-download___de",
    path: "/de/virtual-storage-appliance/setup-file-download",
    meta: setup_45file_45downloadD5FI3VUytXMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/setup-file-download.vue").then(m => m.default || m)
  },
  {
    name: "virtual-storage-appliance-setup-file-download___fr",
    path: "/fr/virtual-storage-appliance/setup-file-download",
    meta: setup_45file_45downloadD5FI3VUytXMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/setup-file-download.vue").then(m => m.default || m)
  },
  {
    name: "virtual-storage-appliance-setup-file-download___es",
    path: "/es/virtual-storage-appliance/setup-file-download",
    meta: setup_45file_45downloadD5FI3VUytXMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/setup-file-download.vue").then(m => m.default || m)
  },
  {
    name: "welcome-192tb-ootbi-slug___en",
    path: "/welcome-192tb-ootbi/:slug(.*)*",
    meta: _91_46_46_46slug_93TliyBQZ9tfMeta || {},
    component: () => import("/app/pages/welcome-192tb-ootbi/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "welcome-192tb-ootbi-slug___de",
    path: "/de/welcome-192tb-ootbi/:slug(.*)*",
    meta: _91_46_46_46slug_93TliyBQZ9tfMeta || {},
    component: () => import("/app/pages/welcome-192tb-ootbi/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "welcome-192tb-ootbi-slug___fr",
    path: "/fr/welcome-192tb-ootbi/:slug(.*)*",
    meta: _91_46_46_46slug_93TliyBQZ9tfMeta || {},
    component: () => import("/app/pages/welcome-192tb-ootbi/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "welcome-192tb-ootbi-slug___es",
    path: "/es/welcome-192tb-ootbi/:slug(.*)*",
    meta: _91_46_46_46slug_93TliyBQZ9tfMeta || {},
    component: () => import("/app/pages/welcome-192tb-ootbi/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "zero-trust-data-resilience-slug___en",
    path: "/zero-trust-data-resilience/:slug(.*)*",
    meta: _91_46_46_46slug_93zCgw7Pfr41Meta || {},
    component: () => import("/app/pages/zero-trust-data-resilience/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "zero-trust-data-resilience-slug___de",
    path: "/de/zero-trust-data-resilience/:slug(.*)*",
    meta: _91_46_46_46slug_93zCgw7Pfr41Meta || {},
    component: () => import("/app/pages/zero-trust-data-resilience/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "zero-trust-data-resilience-slug___fr",
    path: "/fr/zero-trust-data-resilience/:slug(.*)*",
    meta: _91_46_46_46slug_93zCgw7Pfr41Meta || {},
    component: () => import("/app/pages/zero-trust-data-resilience/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "zero-trust-data-resilience-slug___es",
    path: "/es/zero-trust-data-resilience/:slug(.*)*",
    meta: _91_46_46_46slug_93zCgw7Pfr41Meta || {},
    component: () => import("/app/pages/zero-trust-data-resilience/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-events-slug___en",
    path: "/newsroom/events/:slug()",
    component: () => import("/app/pages/newsroom/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-events-slug___de",
    path: "/de/newsroom/events/:slug()",
    component: () => import("/app/pages/newsroom/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-events-slug___fr",
    path: "/fr/newsroom/events/:slug()",
    component: () => import("/app/pages/newsroom/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-events-slug___es",
    path: "/es/newsroom/events/:slug()",
    component: () => import("/app/pages/newsroom/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-media-kit___en",
    path: "/newsroom/media-kit",
    component: () => import("/app/pages/newsroom/media-kit/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-media-kit___de",
    path: "/de/newsroom/media-kit",
    component: () => import("/app/pages/newsroom/media-kit/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-media-kit___fr",
    path: "/fr/newsroom/media-kit",
    component: () => import("/app/pages/newsroom/media-kit/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-media-kit___es",
    path: "/es/newsroom/media-kit",
    component: () => import("/app/pages/newsroom/media-kit/index.vue").then(m => m.default || m)
  },
  {
    name: "newsroom-press-releases-slug___en",
    path: "/newsroom/press-releases/:slug()",
    component: () => import("/app/pages/newsroom/press-releases/[slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-press-releases-slug___de",
    path: "/de/newsroom/press-releases/:slug()",
    component: () => import("/app/pages/newsroom/press-releases/[slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-press-releases-slug___fr",
    path: "/fr/newsroom/press-releases/:slug()",
    component: () => import("/app/pages/newsroom/press-releases/[slug].vue").then(m => m.default || m)
  },
  {
    name: "newsroom-press-releases-slug___es",
    path: "/es/newsroom/press-releases/:slug()",
    component: () => import("/app/pages/newsroom/press-releases/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-analyst-reports-slug___en",
    path: "/resources/analyst-reports/:slug()",
    component: () => import("/app/pages/resources/analyst-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-analyst-reports-slug___de",
    path: "/de/resources/analyst-reports/:slug()",
    component: () => import("/app/pages/resources/analyst-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-analyst-reports-slug___fr",
    path: "/fr/resources/analyst-reports/:slug()",
    component: () => import("/app/pages/resources/analyst-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-analyst-reports-slug___es",
    path: "/es/resources/analyst-reports/:slug()",
    component: () => import("/app/pages/resources/analyst-reports/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-case-studies-slug___en",
    path: "/resources/case-studies/:slug()",
    component: () => import("/app/pages/resources/case-studies/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-case-studies-slug___de",
    path: "/de/resources/case-studies/:slug()",
    component: () => import("/app/pages/resources/case-studies/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-case-studies-slug___fr",
    path: "/fr/resources/case-studies/:slug()",
    component: () => import("/app/pages/resources/case-studies/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-case-studies-slug___es",
    path: "/es/resources/case-studies/:slug()",
    component: () => import("/app/pages/resources/case-studies/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-technical-papers-data-sheet-ootbi-the-best-storage-for-veeam___en",
    path: "/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam",
    component: () => import("/app/pages/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam.vue").then(m => m.default || m)
  },
  {
    name: "resources-technical-papers-data-sheet-ootbi-the-best-storage-for-veeam___de",
    path: "/de/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam",
    component: () => import("/app/pages/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam.vue").then(m => m.default || m)
  },
  {
    name: "resources-technical-papers-data-sheet-ootbi-the-best-storage-for-veeam___fr",
    path: "/fr/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam",
    component: () => import("/app/pages/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam.vue").then(m => m.default || m)
  },
  {
    name: "resources-technical-papers-data-sheet-ootbi-the-best-storage-for-veeam___es",
    path: "/es/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam",
    component: () => import("/app/pages/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam.vue").then(m => m.default || m)
  },
  {
    name: "technical___en",
    path: "/technical",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "technical___de",
    path: "/de/technical",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "technical___fr",
    path: "/fr/technical",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "technical___es",
    path: "/es/technical",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "business___en",
    path: "/business",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "business___de",
    path: "/de/business",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "business___fr",
    path: "/fr/business",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "business___es",
    path: "/es/business",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/machine-translation-disclaimer/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/machine-translation-disclaimer/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/machine-translation-disclaimer/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/machine-translation-disclaimer/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/machine-translation-disclaimer/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/privacy-policy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/privacy-policy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/privacy-policy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/privacy-policy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/privacy-policy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/object-first-security-policy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/object-first-security-policy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/object-first-security-policy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/object-first-security-policy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/object-first-security-policy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/3-reasons-ootbi-is-best-for-veeam/vm-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/3-reasons-ootbi-is-best-for-veeam/vm-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/3-reasons-ootbi-is-best-for-veeam/vm-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/3-reasons-ootbi-is-best-for-veeam/vm-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/3-reasons-ootbi-is-best-for-veeam/vm-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/3-reasons-ootbi-is-best-for-veeam/s3-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/3-reasons-ootbi-is-best-for-veeam/s3-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/3-reasons-ootbi-is-best-for-veeam/s3-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/3-reasons-ootbi-is-best-for-veeam/s3-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/3-reasons-ootbi-is-best-for-veeam/s3-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/3-reasons-ootbi-is-best-for-veeam/ransomware-immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/3-reasons-ootbi-is-best-for-veeam/ransomware-immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/3-reasons-ootbi-is-best-for-veeam/ransomware-immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/3-reasons-ootbi-is-best-for-veeam/ransomware-immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/3-reasons-ootbi-is-best-for-veeam/ransomware-immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/3-reasons-ootbi-is-best-for-veeam/ransomware-data-protection/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/3-reasons-ootbi-is-best-for-veeam/ransomware-data-protection/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/3-reasons-ootbi-is-best-for-veeam/ransomware-data-protection/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/3-reasons-ootbi-is-best-for-veeam/ransomware-data-protection/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/3-reasons-ootbi-is-best-for-veeam/ransomware-data-protection/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/3-reasons-ootbi-is-best-for-veeam/immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/3-reasons-ootbi-is-best-for-veeam/immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/3-reasons-ootbi-is-best-for-veeam/immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/3-reasons-ootbi-is-best-for-veeam/immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/3-reasons-ootbi-is-best-for-veeam/immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/3-reasons-ootbi-is-best-for-veeam/hci-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/3-reasons-ootbi-is-best-for-veeam/hci-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/3-reasons-ootbi-is-best-for-veeam/hci-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/3-reasons-ootbi-is-best-for-veeam/hci-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/3-reasons-ootbi-is-best-for-veeam/hci-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/3-reasons-ootbi-is-best-for-veeam/enterprise-data-security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/3-reasons-ootbi-is-best-for-veeam/enterprise-data-security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/3-reasons-ootbi-is-best-for-veeam/enterprise-data-security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/3-reasons-ootbi-is-best-for-veeam/enterprise-data-security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/3-reasons-ootbi-is-best-for-veeam/enterprise-data-security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/3-reasons-ootbi-is-best-for-veeam/veeam-backup-storage-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/3-reasons-ootbi-is-best-for-veeam/veeam-backup-storage-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/3-reasons-ootbi-is-best-for-veeam/veeam-backup-storage-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/3-reasons-ootbi-is-best-for-veeam/veeam-backup-storage-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/3-reasons-ootbi-is-best-for-veeam/veeam-backup-storage-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/3-reasons-ootbi-is-best-for-veeam/backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/3-reasons-ootbi-is-best-for-veeam/backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/3-reasons-ootbi-is-best-for-veeam/backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/3-reasons-ootbi-is-best-for-veeam/backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/3-reasons-ootbi-is-best-for-veeam/backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/team-up-with-objectfirst-and-arrow/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/team-up-with-objectfirst-and-arrow/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/team-up-with-objectfirst-and-arrow/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/team-up-with-objectfirst-and-arrow/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/team-up-with-objectfirst-and-arrow/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/guides/immutability/immutable-backup-and-immutable-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/guides/immutability/immutable-backup-and-immutable-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/guides/immutability/immutable-backup-and-immutable-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/guides/immutability/immutable-backup-and-immutable-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/guides/immutability/immutable-backup-and-immutable-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/home/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/home/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/home/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/home/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/home/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ootbi-tech-brief-download/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/ootbi-tech-brief-download/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/ootbi-tech-brief-download/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/ootbi-tech-brief-download/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/ootbi-tech-brief-download/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ransomware-prevention-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/ransomware-prevention-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/ransomware-prevention-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/ransomware-prevention-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/ransomware-prevention-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ransomware-survival-guide-for-techies/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/ransomware-survival-guide-for-techies/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/ransomware-survival-guide-for-techies/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/ransomware-survival-guide-for-techies/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/ransomware-survival-guide-for-techies/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/quick-start-guide-side-a/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/quick-start-guide-side-a/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/quick-start-guide-side-a/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/quick-start-guide-side-a/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/quick-start-guide-side-a/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/quick-start-guide-side-b/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/quick-start-guide-side-b/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/quick-start-guide-side-b/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/quick-start-guide-side-b/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/quick-start-guide-side-b/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/gift-campaing/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/gift-campaing/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/gift-campaing/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/gift-campaing/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/gift-campaing/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/hci-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/hci-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/hci-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/hci-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/hci-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/vm-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/vm-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/vm-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/vm-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/vm-backup-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/s3-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/s3-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/s3-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/s3-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/s3-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise-data-security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/enterprise-data-security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/enterprise-data-security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/enterprise-data-security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/enterprise-data-security/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/veeam-backup-storage-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/veeam-backup-storage-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/veeam-backup-storage-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/veeam-backup-storage-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/veeam-backup-storage-solution/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ransomware-data-protection/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/ransomware-data-protection/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/ransomware-data-protection/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/ransomware-data-protection/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/ransomware-data-protection/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ransomware-immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/ransomware-immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/ransomware-immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/ransomware-immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/ransomware-immutable-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/guides/cloud-vs-on-premise-vs-hybrid-backup-which-is-better/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/guides/cloud-vs-on-premise-vs-hybrid-backup-which-is-better/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/guides/cloud-vs-on-premise-vs-hybrid-backup-which-is-better/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/guides/cloud-vs-on-premise-vs-hybrid-backup-which-is-better/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/guides/cloud-vs-on-premise-vs-hybrid-backup-which-is-better/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/guides/data-security/the-importance-of-data-security-in-todays-cyber-threat-landscape/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/guides/data-security/the-importance-of-data-security-in-todays-cyber-threat-landscape/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/guides/data-security/the-importance-of-data-security-in-todays-cyber-threat-landscape/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/guides/data-security/the-importance-of-data-security-in-todays-cyber-threat-landscape/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/guides/data-security/the-importance-of-data-security-in-todays-cyber-threat-landscape/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/blog/data-backup-definition-options-types-and-solutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/data-backup-definition-options-types-and-solutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/blog/data-backup-definition-options-types-and-solutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/blog/data-backup-definition-options-types-and-solutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/blog/data-backup-definition-options-types-and-solutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/blog/what-is-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/what-is-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/blog/what-is-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/blog/what-is-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/blog/what-is-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/blog/deep-dive-what-is-object-storage-and-how-does-it-work/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/deep-dive-what-is-object-storage-and-how-does-it-work/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/blog/deep-dive-what-is-object-storage-and-how-does-it-work/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/blog/deep-dive-what-is-object-storage-and-how-does-it-work/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/blog/deep-dive-what-is-object-storage-and-how-does-it-work/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/events/veeam-alliance-beach-challenge-in-chicago/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/events/veeam-alliance-beach-challenge-in-chicago/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/events/veeam-alliance-beach-challenge-in-chicago/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/events/veeam-alliance-beach-challenge-in-chicago/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/events/veeam-alliance-beach-challenge-in-chicago/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom-category/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom-category/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom-category/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom-category/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom-category/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom-category/in-the-news/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom-category/in-the-news/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom-category/in-the-news/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom-category/in-the-news/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom-category/in-the-news/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ootbi-60-day-money-back-guarantee/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/ootbi-60-day-money-back-guarantee/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/ootbi-60-day-money-back-guarantee/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/ootbi-60-day-money-back-guarantee/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/ootbi-60-day-money-back-guarantee/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/partner-webinar-june-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/partner-webinar-june-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/partner-webinar-june-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/partner-webinar-june-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/partner-webinar-june-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/guides/data-storage/purpose-built-backup-applianc-pbba/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/guides/data-storage/purpose-built-backup-applianc-pbba/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/guides/data-storage/purpose-built-backup-applianc-pbba/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/guides/data-storage/purpose-built-backup-applianc-pbba/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/guides/data-storage/purpose-built-backup-applianc-pbba/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/resources/analyst-reports/infographic-by-enterprise-strategy-group-esg-defeating-ransomware-ootbi-by-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/resources/analyst-reports/infographic-by-enterprise-strategy-group-esg-defeating-ransomware-ootbi-by-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/resources/analyst-reports/infographic-by-enterprise-strategy-group-esg-defeating-ransomware-ootbi-by-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/resources/analyst-reports/infographic-by-enterprise-strategy-group-esg-defeating-ransomware-ootbi-by-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/resources/analyst-reports/infographic-by-enterprise-strategy-group-esg-defeating-ransomware-ootbi-by-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/resources/analyst-reports/object-first-ootbi-a-laser-focused-opportunity-for-the-channel/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/resources/analyst-reports/object-first-ootbi-a-laser-focused-opportunity-for-the-channel/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/resources/analyst-reports/object-first-ootbi-a-laser-focused-opportunity-for-the-channel/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/resources/analyst-reports/object-first-ootbi-a-laser-focused-opportunity-for-the-channel/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/resources/analyst-reports/object-first-ootbi-a-laser-focused-opportunity-for-the-channel/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/spif-terms-and-conditions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/spif-terms-and-conditions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/spif-terms-and-conditions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/spif-terms-and-conditions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/spif-terms-and-conditions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/partner-rewards-terms-and-conditions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/partner-rewards-terms-and-conditions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/partner-rewards-terms-and-conditions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/partner-rewards-terms-and-conditions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/partner-rewards-terms-and-conditions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/faq/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/faq/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/faq/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/faq/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/faq/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/resources/white-papers/zero-trust-and-secure-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/resources/white-papers/zero-trust-and-secure-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/resources/white-papers/zero-trust-and-secure-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/resources/white-papers/zero-trust-and-secure-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/resources/white-papers/zero-trust-and-secure-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/blog/view-all/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/view-all/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/blog/view-all/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/blog/view-all/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/blog/view-all/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/can-you-beat-the-ransomware-challenge/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/can-you-beat-the-ransomware-challenge/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/can-you-beat-the-ransomware-challenge/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/can-you-beat-the-ransomware-challenge/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/can-you-beat-the-ransomware-challenge/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/can-you-beat-the-ransomware-challenge.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/can-you-beat-the-ransomware-challenge.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/can-you-beat-the-ransomware-challenge.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/can-you-beat-the-ransomware-challenge.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/can-you-beat-the-ransomware-challenge.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/contact-sales/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/contact-sales/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/contact-sales/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/contact-sales/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/contact-sales/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/resources/white-papers/5-reasons-object-first-is-best-for-veeam/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/resources/white-papers/5-reasons-object-first-is-best-for-veeam/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/resources/white-papers/5-reasons-object-first-is-best-for-veeam/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/resources/white-papers/5-reasons-object-first-is-best-for-veeam/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/resources/white-papers/5-reasons-object-first-is-best-for-veeam/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/webinar/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/webinar/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/webinar/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/webinar/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/webinar/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/news/visit-object-first-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/news/visit-object-first-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/news/visit-object-first-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/news/visit-object-first-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/news/visit-object-first-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/news/object-first-had-an-outstanding-showing-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/news/object-first-had-an-outstanding-showing-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/news/object-first-had-an-outstanding-showing-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/news/object-first-had-an-outstanding-showing-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/news/object-first-had-an-outstanding-showing-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/news/veeam-founders-launch-object-first-a-new-company-purpose-built-for-veeam-customers-to-ransomware-proof-backups/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/news/veeam-founders-launch-object-first-a-new-company-purpose-built-for-veeam-customers-to-ransomware-proof-backups/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/news/veeam-founders-launch-object-first-a-new-company-purpose-built-for-veeam-customers-to-ransomware-proof-backups/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/news/veeam-founders-launch-object-first-a-new-company-purpose-built-for-veeam-customers-to-ransomware-proof-backups/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/news/veeam-founders-launch-object-first-a-new-company-purpose-built-for-veeam-customers-to-ransomware-proof-backups/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/support-ticket/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/support-ticket/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/support-ticket/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/support-ticket/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/support-ticket/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/hardware-backup-for-your-business-types-pros-cons/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/hardware-backup-for-your-business-types-pros-cons/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/hardware-backup-for-your-business-types-pros-cons/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/hardware-backup-for-your-business-types-pros-cons/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/hardware-backup-for-your-business-types-pros-cons/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/unique-product-launch",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/unique-product-launch",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/unique-product-launch",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/unique-product-launch",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/unique-product-launch",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/resources-category/white-papers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/resources-category/white-papers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/resources-category/white-papers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/resources-category/white-papers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/resources-category/white-papers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/resources-category/technical-papers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/resources-category/technical-papers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/resources-category/technical-papers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/resources-category/technical-papers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/resources-category/technical-papers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/resources-category/analyst-reports/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/resources-category/analyst-reports/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/resources-category/analyst-reports/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/resources-category/analyst-reports/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/resources-category/analyst-reports/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/resources-category/case-studies/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/resources-category/case-studies/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/resources-category/case-studies/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/resources-category/case-studies/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/resources-category/case-studies/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom-category/media-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom-category/media-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom-category/media-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom-category/media-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom-category/media-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom-category/press-releases/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom-category/press-releases/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom-category/press-releases/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom-category/press-releases/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom-category/press-releases/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom-category/events/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom-category/events/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom-category/events/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom-category/events/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom-category/events/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/resources/analyst-reports/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/resources/analyst-reports/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/resources/analyst-reports/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/resources/analyst-reports/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/resources/analyst-reports/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom-category/in-the-news/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom-category/in-the-news/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom-category/in-the-news/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom-category/in-the-news/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom-category/in-the-news/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom-category/in-the-news/page/3/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom-category/in-the-news/page/3/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom-category/in-the-news/page/3/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom-category/in-the-news/page/3/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom-category/in-the-news/page/3/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom-category/in-the-news/page/4/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom-category/in-the-news/page/4/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom-category/in-the-news/page/4/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom-category/in-the-news/page/4/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom-category/in-the-news/page/4/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom-category/in-the-news/page/5/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom-category/in-the-news/page/5/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom-category/in-the-news/page/5/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom-category/in-the-news/page/5/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom-category/in-the-news/page/5/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom-category/in-the-news/page/6/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom-category/in-the-news/page/6/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom-category/in-the-news/page/6/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom-category/in-the-news/page/6/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom-category/in-the-news/page/6/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/media-kit/media-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/media-kit/media-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/media-kit/media-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/media-kit/media-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/media-kit/media-kit/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/knowledge-base/cloud-vs-on-premise-vs-hybrid-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/knowledge-base/cloud-vs-on-premise-vs-hybrid-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/knowledge-base/cloud-vs-on-premise-vs-hybrid-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/knowledge-base/cloud-vs-on-premise-vs-hybrid-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/knowledge-base/cloud-vs-on-premise-vs-hybrid-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/knowledge-base/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/knowledge-base/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/knowledge-base/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/knowledge-base/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/knowledge-base/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/knowledge-base",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/knowledge-base",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/knowledge-base",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/knowledge-base",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/knowledge-base",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/page/2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/page/3/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/page/3/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/page/3/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/page/3/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/page/3/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/page/4/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/page/4/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/page/4/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/page/4/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/page/4/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/page/5/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/page/5/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/page/5/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/page/5/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/page/5/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about-us",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/about-us",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/about-us",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/about-us",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/about-us",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/newsroom/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/newsroom/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/newsroom/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/newsroom/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/newsroom/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/resources/resources/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/resources/resources/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/resources/resources/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/resources/resources/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/resources/resources/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/4-reasons-ootbi-is-best-for-veeam",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/4-reasons-ootbi-is-best-for-veeam",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/4-reasons-ootbi-is-best-for-veeam",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/4-reasons-ootbi-is-best-for-veeam",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/4-reasons-ootbi-is-best-for-veeam",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/4-reasons-ootbi-is-best-for-veeam/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/4-reasons-ootbi-is-best-for-veeam/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/4-reasons-ootbi-is-best-for-veeam/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/4-reasons-ootbi-is-best-for-veeam/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/4-reasons-ootbi-is-best-for-veeam/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/object-storage-backup-appliance-ransomware-proof/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/object-storage-backup-appliance-ransomware-proof/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/object-storage-backup-appliance-ransomware-proof/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/object-storage-backup-appliance-ransomware-proof/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/object-storage-backup-appliance-ransomware-proof/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/object-storage-vs-block-storage-vs-file-storage-full-comparison/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/object-storage-vs-block-storage-vs-file-storage-full-comparison/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/object-storage-vs-block-storage-vs-file-storage-full-comparison/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/object-storage-vs-block-storage-vs-file-storage-full-comparison/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/object-storage-vs-block-storage-vs-file-storage-full-comparison/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/lilith-ransomware-how-to-keep-backups-safe/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/lilith-ransomware-how-to-keep-backups-safe/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/lilith-ransomware-how-to-keep-backups-safe/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/lilith-ransomware-how-to-keep-backups-safe/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/lilith-ransomware-how-to-keep-backups-safe/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/file-storage-what-is-it/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/file-storage-what-is-it/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/file-storage-what-is-it/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/file-storage-what-is-it/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/file-storage-what-is-it/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/block-storage-pros-cons-use-cases/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/block-storage-pros-cons-use-cases/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/block-storage-pros-cons-use-cases/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/block-storage-pros-cons-use-cases/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/block-storage-pros-cons-use-cases/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/what-is-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/what-is-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/what-is-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/what-is-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/what-is-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cloud-storage-build-your-cloud-plan/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/cloud-storage-build-your-cloud-plan/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/cloud-storage-build-your-cloud-plan/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/cloud-storage-build-your-cloud-plan/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/cloud-storage-build-your-cloud-plan/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ultimate-data-recoverability-part-1/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/ultimate-data-recoverability-part-1/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/ultimate-data-recoverability-part-1/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/ultimate-data-recoverability-part-1/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/ultimate-data-recoverability-part-1/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/how-to-protect-against-ransomware-personal-experience/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/how-to-protect-against-ransomware-personal-experience/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/how-to-protect-against-ransomware-personal-experience/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/how-to-protect-against-ransomware-personal-experience/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/how-to-protect-against-ransomware-personal-experience/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ultimate-data-recoverability-part-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/ultimate-data-recoverability-part-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/ultimate-data-recoverability-part-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/ultimate-data-recoverability-part-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/ultimate-data-recoverability-part-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/deep-dive-what-is-object-storage-and-how-does-it-work/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/deep-dive-what-is-object-storage-and-how-does-it-work/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/deep-dive-what-is-object-storage-and-how-does-it-work/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/deep-dive-what-is-object-storage-and-how-does-it-work/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/deep-dive-what-is-object-storage-and-how-does-it-work/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/data-backup-definition-options-types-and-solutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/data-backup-definition-options-types-and-solutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/data-backup-definition-options-types-and-solutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/data-backup-definition-options-types-and-solutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/data-backup-definition-options-types-and-solutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/world-backup-day-4-tips-for-data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/world-backup-day-4-tips-for-data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/world-backup-day-4-tips-for-data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/world-backup-day-4-tips-for-data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/world-backup-day-4-tips-for-data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/from-small-businesses-to-big-tech-everyone-has-a-role-in-ensuring-cybersecurity/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/from-small-businesses-to-big-tech-everyone-has-a-role-in-ensuring-cybersecurity/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/from-small-businesses-to-big-tech-everyone-has-a-role-in-ensuring-cybersecurity/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/from-small-businesses-to-big-tech-everyone-has-a-role-in-ensuring-cybersecurity/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/from-small-businesses-to-big-tech-everyone-has-a-role-in-ensuring-cybersecurity/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/join-ootbi-at-veeamon-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/join-ootbi-at-veeamon-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/join-ootbi-at-veeamon-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/join-ootbi-at-veeamon-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/join-ootbi-at-veeamon-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/from-object-to-success-celebrating-object-firsts-inception/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/from-object-to-success-celebrating-object-firsts-inception/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/from-object-to-success-celebrating-object-firsts-inception/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/from-object-to-success-celebrating-object-firsts-inception/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/from-object-to-success-celebrating-object-firsts-inception/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about-us/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/about-us/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/about-us/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/about-us/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/about-us/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/events/object-firsts-vmware-explore-2023-highlights/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/events/object-firsts-vmware-explore-2023-highlights/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/events/object-firsts-vmware-explore-2023-highlights/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/events/object-firsts-vmware-explore-2023-highlights/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/events/object-firsts-vmware-explore-2023-highlights/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/events/object-first-had-an-outstanding-showing-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/events/object-first-had-an-outstanding-showing-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/events/object-first-had-an-outstanding-showing-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/events/object-first-had-an-outstanding-showing-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/events/object-first-had-an-outstanding-showing-at-vmware-explore-2022/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/veeam-centric-backup-storage-company-object-first-looks-for-new-partners-at-vmware-explore/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/veeam-centric-backup-storage-company-object-first-looks-for-new-partners-at-vmware-explore/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/veeam-centric-backup-storage-company-object-first-looks-for-new-partners-at-vmware-explore/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/veeam-centric-backup-storage-company-object-first-looks-for-new-partners-at-vmware-explore/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/veeam-centric-backup-storage-company-object-first-looks-for-new-partners-at-vmware-explore/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/object-first-introduces-ootbi-ransomware-proof-storage-appliance-designed-for-veeam-customers-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/object-first-introduces-ootbi-ransomware-proof-storage-appliance-designed-for-veeam-customers-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/object-first-introduces-ootbi-ransomware-proof-storage-appliance-designed-for-veeam-customers-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/object-first-introduces-ootbi-ransomware-proof-storage-appliance-designed-for-veeam-customers-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/object-first-introduces-ootbi-ransomware-proof-storage-appliance-designed-for-veeam-customers-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/the-next-evolution-of-cyber-defense-ransomware-proof-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/the-next-evolution-of-cyber-defense-ransomware-proof-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/the-next-evolution-of-cyber-defense-ransomware-proof-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/the-next-evolution-of-cyber-defense-ransomware-proof-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/the-next-evolution-of-cyber-defense-ransomware-proof-object-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/managed-service-providers-need-a-strategic-approach-for-fortifying-cyberdefense/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/managed-service-providers-need-a-strategic-approach-for-fortifying-cyberdefense/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/managed-service-providers-need-a-strategic-approach-for-fortifying-cyberdefense/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/managed-service-providers-need-a-strategic-approach-for-fortifying-cyberdefense/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/managed-service-providers-need-a-strategic-approach-for-fortifying-cyberdefense/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/data-privacy-day-2024-tips-and-views-from-top-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/data-privacy-day-2024-tips-and-views-from-top-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/data-privacy-day-2024-tips-and-views-from-top-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/data-privacy-day-2024-tips-and-views-from-top-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/data-privacy-day-2024-tips-and-views-from-top-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/world-backup-day-2023-the-importance-of-proactive-measures-to-prevent-data-loss-and-theft/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/world-backup-day-2023-the-importance-of-proactive-measures-to-prevent-data-loss-and-theft/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/world-backup-day-2023-the-importance-of-proactive-measures-to-prevent-data-loss-and-theft/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/world-backup-day-2023-the-importance-of-proactive-measures-to-prevent-data-loss-and-theft/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/world-backup-day-2023-the-importance-of-proactive-measures-to-prevent-data-loss-and-theft/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/how-to-prepare-for-data-disruption-in-the-healthcare-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/how-to-prepare-for-data-disruption-in-the-healthcare-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/how-to-prepare-for-data-disruption-in-the-healthcare-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/how-to-prepare-for-data-disruption-in-the-healthcare-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/how-to-prepare-for-data-disruption-in-the-healthcare-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/emerging-tech-to-help-guard-against-the-malevolence-of-cloud-outages/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/emerging-tech-to-help-guard-against-the-malevolence-of-cloud-outages/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/emerging-tech-to-help-guard-against-the-malevolence-of-cloud-outages/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/emerging-tech-to-help-guard-against-the-malevolence-of-cloud-outages/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/emerging-tech-to-help-guard-against-the-malevolence-of-cloud-outages/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/industry-experts-share-insights-and-reflections-on-earth-day-2023-celebrating-our-planet/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/industry-experts-share-insights-and-reflections-on-earth-day-2023-celebrating-our-planet/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/industry-experts-share-insights-and-reflections-on-earth-day-2023-celebrating-our-planet/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/industry-experts-share-insights-and-reflections-on-earth-day-2023-celebrating-our-planet/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/industry-experts-share-insights-and-reflections-on-earth-day-2023-celebrating-our-planet/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/945-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/945-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/945-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/945-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/945-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/object-first-2024-predictions-navigating-the-ai-and-data-storage-landscape-in-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/object-first-2024-predictions-navigating-the-ai-and-data-storage-landscape-in-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/object-first-2024-predictions-navigating-the-ai-and-data-storage-landscape-in-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/object-first-2024-predictions-navigating-the-ai-and-data-storage-landscape-in-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/object-first-2024-predictions-navigating-the-ai-and-data-storage-landscape-in-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/the-10-hottest-storage-startups-of-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/the-10-hottest-storage-startups-of-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/the-10-hottest-storage-startups-of-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/the-10-hottest-storage-startups-of-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/the-10-hottest-storage-startups-of-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/data-privacy-day-2023-tips-and-views-from-top-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/data-privacy-day-2023-tips-and-views-from-top-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/data-privacy-day-2023-tips-and-views-from-top-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/data-privacy-day-2023-tips-and-views-from-top-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/data-privacy-day-2023-tips-and-views-from-top-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/object-first-2023-predictions-data-backup-will-experience-a-moment-of-reckoning-on-ransomware-resiliency-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/object-first-2023-predictions-data-backup-will-experience-a-moment-of-reckoning-on-ransomware-resiliency-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/object-first-2023-predictions-data-backup-will-experience-a-moment-of-reckoning-on-ransomware-resiliency-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/object-first-2023-predictions-data-backup-will-experience-a-moment-of-reckoning-on-ransomware-resiliency-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/object-first-2023-predictions-data-backup-will-experience-a-moment-of-reckoning-on-ransomware-resiliency-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/new-ransomware-variants-tactics-rattle-financial-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/new-ransomware-variants-tactics-rattle-financial-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/new-ransomware-variants-tactics-rattle-financial-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/new-ransomware-variants-tactics-rattle-financial-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/new-ransomware-variants-tactics-rattle-financial-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/dont-underestimate-vulnerabilities-in-the-cloud-adopt-hybrid-to-stay-protected/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/dont-underestimate-vulnerabilities-in-the-cloud-adopt-hybrid-to-stay-protected/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/dont-underestimate-vulnerabilities-in-the-cloud-adopt-hybrid-to-stay-protected/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/dont-underestimate-vulnerabilities-in-the-cloud-adopt-hybrid-to-stay-protected/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/dont-underestimate-vulnerabilities-in-the-cloud-adopt-hybrid-to-stay-protected/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/object-first-shares-details-on-backup-storage-play/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/object-first-shares-details-on-backup-storage-play/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/object-first-shares-details-on-backup-storage-play/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/object-first-shares-details-on-backup-storage-play/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/object-first-shares-details-on-backup-storage-play/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/object-first-exits-stealth-focuses-exclusively-on-veeam-environments/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/object-first-exits-stealth-focuses-exclusively-on-veeam-environments/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/object-first-exits-stealth-focuses-exclusively-on-veeam-environments/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/object-first-exits-stealth-focuses-exclusively-on-veeam-environments/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/object-first-exits-stealth-focuses-exclusively-on-veeam-environments/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/45-world-backup-day-quotes-from-32-experts-for-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/45-world-backup-day-quotes-from-32-experts-for-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/45-world-backup-day-quotes-from-32-experts-for-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/45-world-backup-day-quotes-from-32-experts-for-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/45-world-backup-day-quotes-from-32-experts-for-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/75-of-consumers-prepared-to-ditch-brands-hit-by-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/75-of-consumers-prepared-to-ditch-brands-hit-by-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/75-of-consumers-prepared-to-ditch-brands-hit-by-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/75-of-consumers-prepared-to-ditch-brands-hit-by-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/75-of-consumers-prepared-to-ditch-brands-hit-by-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/how-tech-leaders-can-reduce-workplace-burnout/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/how-tech-leaders-can-reduce-workplace-burnout/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/how-tech-leaders-can-reduce-workplace-burnout/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/how-tech-leaders-can-reduce-workplace-burnout/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/how-tech-leaders-can-reduce-workplace-burnout/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/time-to-celebrate-world-backup-day-2023-hear-from-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/time-to-celebrate-world-backup-day-2023-hear-from-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/time-to-celebrate-world-backup-day-2023-hear-from-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/time-to-celebrate-world-backup-day-2023-hear-from-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/time-to-celebrate-world-backup-day-2023-hear-from-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/58-of-employees-are-open-to-abandoning-passwords/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/58-of-employees-are-open-to-abandoning-passwords/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/58-of-employees-are-open-to-abandoning-passwords/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/58-of-employees-are-open-to-abandoning-passwords/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/58-of-employees-are-open-to-abandoning-passwords/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/object-first-ootbi-backup-appliance-for-veeam-launched/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/object-first-ootbi-backup-appliance-for-veeam-launched/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/object-first-ootbi-backup-appliance-for-veeam-launched/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/object-first-ootbi-backup-appliance-for-veeam-launched/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/object-first-ootbi-backup-appliance-for-veeam-launched/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/12-cybersecurity-trends-every-ciso-must-prepare-for-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/12-cybersecurity-trends-every-ciso-must-prepare-for-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/12-cybersecurity-trends-every-ciso-must-prepare-for-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/12-cybersecurity-trends-every-ciso-must-prepare-for-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/12-cybersecurity-trends-every-ciso-must-prepare-for-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/why-object-storage-for-backups-can-keep-schools-data-safer-when-ransomware-comes-calling/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/why-object-storage-for-backups-can-keep-schools-data-safer-when-ransomware-comes-calling/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/why-object-storage-for-backups-can-keep-schools-data-safer-when-ransomware-comes-calling/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/why-object-storage-for-backups-can-keep-schools-data-safer-when-ransomware-comes-calling/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/why-object-storage-for-backups-can-keep-schools-data-safer-when-ransomware-comes-calling/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/object-first-2023-predictions-a-new-era-of-improvement-for-tech/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/object-first-2023-predictions-a-new-era-of-improvement-for-tech/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/object-first-2023-predictions-a-new-era-of-improvement-for-tech/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/object-first-2023-predictions-a-new-era-of-improvement-for-tech/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/object-first-2023-predictions-a-new-era-of-improvement-for-tech/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/531-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/531-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/531-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/531-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/531-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/international-womens-day-how-women-have-overcome-challenges-in-cyber-to-advance-the-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/international-womens-day-how-women-have-overcome-challenges-in-cyber-to-advance-the-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/international-womens-day-how-women-have-overcome-challenges-in-cyber-to-advance-the-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/international-womens-day-how-women-have-overcome-challenges-in-cyber-to-advance-the-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/international-womens-day-how-women-have-overcome-challenges-in-cyber-to-advance-the-industry/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/veeam-believes-you-need-cyber-insurance-insurance/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/veeam-believes-you-need-cyber-insurance-insurance/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/veeam-believes-you-need-cyber-insurance-insurance/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/veeam-believes-you-need-cyber-insurance-insurance/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/veeam-believes-you-need-cyber-insurance-insurance/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/data-privacy-day-2023-7-experts-share-data-protection-best-practices/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/data-privacy-day-2023-7-experts-share-data-protection-best-practices/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/data-privacy-day-2023-7-experts-share-data-protection-best-practices/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/data-privacy-day-2023-7-experts-share-data-protection-best-practices/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/data-privacy-day-2023-7-experts-share-data-protection-best-practices/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/bolstering-security-ahead-of-july-4th-weekend-insights-from-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/bolstering-security-ahead-of-july-4th-weekend-insights-from-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/bolstering-security-ahead-of-july-4th-weekend-insights-from-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/bolstering-security-ahead-of-july-4th-weekend-insights-from-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/bolstering-security-ahead-of-july-4th-weekend-insights-from-industry-experts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/events/vmblog-veeamon-2023-qa-find-out-what-object-first-has-planned-for-the-event/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/events/vmblog-veeamon-2023-qa-find-out-what-object-first-has-planned-for-the-event/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/events/vmblog-veeamon-2023-qa-find-out-what-object-first-has-planned-for-the-event/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/events/vmblog-veeamon-2023-qa-find-out-what-object-first-has-planned-for-the-event/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/events/vmblog-veeamon-2023-qa-find-out-what-object-first-has-planned-for-the-event/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/show-your-appreciation-celebrating-sysadminday-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/show-your-appreciation-celebrating-sysadminday-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/show-your-appreciation-celebrating-sysadminday-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/show-your-appreciation-celebrating-sysadminday-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/show-your-appreciation-celebrating-sysadminday-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/optimized-and-resilient-object-storage-from-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/optimized-and-resilient-object-storage-from-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/optimized-and-resilient-object-storage-from-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/optimized-and-resilient-object-storage-from-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/optimized-and-resilient-object-storage-from-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/achieving-data-immutability-in-a-backup-and-recovery-strategy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/achieving-data-immutability-in-a-backup-and-recovery-strategy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/achieving-data-immutability-in-a-backup-and-recovery-strategy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/achieving-data-immutability-in-a-backup-and-recovery-strategy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/achieving-data-immutability-in-a-backup-and-recovery-strategy/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/ransomwares-prime-target-securing-educational-institutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/ransomwares-prime-target-securing-educational-institutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/ransomwares-prime-target-securing-educational-institutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/ransomwares-prime-target-securing-educational-institutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/ransomwares-prime-target-securing-educational-institutions/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/75-of-consumers-ready-to-ditch-brands-hit-by-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/75-of-consumers-ready-to-ditch-brands-hit-by-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/75-of-consumers-ready-to-ditch-brands-hit-by-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/75-of-consumers-ready-to-ditch-brands-hit-by-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/75-of-consumers-ready-to-ditch-brands-hit-by-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/ransomware-attacks-could-scare-away-75-of-a-companys-cusromers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/ransomware-attacks-could-scare-away-75-of-a-companys-cusromers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/ransomware-attacks-could-scare-away-75-of-a-companys-cusromers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/ransomware-attacks-could-scare-away-75-of-a-companys-cusromers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/ransomware-attacks-could-scare-away-75-of-a-companys-cusromers/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/object-first-2024-predictions-ai-ups-the-ante-in-2024-how-the-rising-threat-will-impact-the-year-ahead/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/object-first-2024-predictions-ai-ups-the-ante-in-2024-how-the-rising-threat-will-impact-the-year-ahead/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/object-first-2024-predictions-ai-ups-the-ante-in-2024-how-the-rising-threat-will-impact-the-year-ahead/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/object-first-2024-predictions-ai-ups-the-ante-in-2024-how-the-rising-threat-will-impact-the-year-ahead/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/object-first-2024-predictions-ai-ups-the-ante-in-2024-how-the-rising-threat-will-impact-the-year-ahead/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/object-first-predicts-cybersecurity-investment-will-increase-substantially-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/object-first-predicts-cybersecurity-investment-will-increase-substantially-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/object-first-predicts-cybersecurity-investment-will-increase-substantially-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/object-first-predicts-cybersecurity-investment-will-increase-substantially-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/object-first-predicts-cybersecurity-investment-will-increase-substantially-in-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/ransomware-your-security-insurance-will-no-longer-save-you/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/ransomware-your-security-insurance-will-no-longer-save-you/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/ransomware-your-security-insurance-will-no-longer-save-you/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/ransomware-your-security-insurance-will-no-longer-save-you/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/ransomware-your-security-insurance-will-no-longer-save-you/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/scary-security-stats-roundup-from-2023-research/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/scary-security-stats-roundup-from-2023-research/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/scary-security-stats-roundup-from-2023-research/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/scary-security-stats-roundup-from-2023-research/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/scary-security-stats-roundup-from-2023-research/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/object-first-2024-predictions-data-recovery-and-resiliency-will-have-its-turn-in-the-spotlight/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/object-first-2024-predictions-data-recovery-and-resiliency-will-have-its-turn-in-the-spotlight/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/object-first-2024-predictions-data-recovery-and-resiliency-will-have-its-turn-in-the-spotlight/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/object-first-2024-predictions-data-recovery-and-resiliency-will-have-its-turn-in-the-spotlight/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/object-first-2024-predictions-data-recovery-and-resiliency-will-have-its-turn-in-the-spotlight/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/industry-experts-share-insights-on-safer-internet-day-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/industry-experts-share-insights-on-safer-internet-day-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/industry-experts-share-insights-on-safer-internet-day-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/industry-experts-share-insights-on-safer-internet-day-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/industry-experts-share-insights-on-safer-internet-day-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/854-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/854-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/854-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/854-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/854-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/latest-cyberthreat-trend-ransomware-actors-now-corrupt-exfiltrated-data/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/latest-cyberthreat-trend-ransomware-actors-now-corrupt-exfiltrated-data/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/latest-cyberthreat-trend-ransomware-actors-now-corrupt-exfiltrated-data/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/latest-cyberthreat-trend-ransomware-actors-now-corrupt-exfiltrated-data/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/latest-cyberthreat-trend-ransomware-actors-now-corrupt-exfiltrated-data/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/inside-veeamon-2023-object-first-ootbi-for-veeam-and-immutability-in-the-fight-against-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/inside-veeamon-2023-object-first-ootbi-for-veeam-and-immutability-in-the-fight-against-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/inside-veeamon-2023-object-first-ootbi-for-veeam-and-immutability-in-the-fight-against-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/inside-veeamon-2023-object-first-ootbi-for-veeam-and-immutability-in-the-fight-against-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/inside-veeamon-2023-object-first-ootbi-for-veeam-and-immutability-in-the-fight-against-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/blocking-ransomware-attacks-with-anthony-cusimano/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/blocking-ransomware-attacks-with-anthony-cusimano/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/blocking-ransomware-attacks-with-anthony-cusimano/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/blocking-ransomware-attacks-with-anthony-cusimano/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/blocking-ransomware-attacks-with-anthony-cusimano/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/cybersecurity-awareness-month-2023-industry-experts-share-their-thoughts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/cybersecurity-awareness-month-2023-industry-experts-share-their-thoughts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/cybersecurity-awareness-month-2023-industry-experts-share-their-thoughts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/cybersecurity-awareness-month-2023-industry-experts-share-their-thoughts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/cybersecurity-awareness-month-2023-industry-experts-share-their-thoughts/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/storage-ticker-december-13/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/storage-ticker-december-13/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/storage-ticker-december-13/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/storage-ticker-december-13/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/storage-ticker-december-13/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/safeguarding-against-the-global-ransomware-threat/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/safeguarding-against-the-global-ransomware-threat/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/safeguarding-against-the-global-ransomware-threat/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/safeguarding-against-the-global-ransomware-threat/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/safeguarding-against-the-global-ransomware-threat/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/national-insider-threat-awareness-month-2023-expert-roundup-bystander-engagement/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/national-insider-threat-awareness-month-2023-expert-roundup-bystander-engagement/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/national-insider-threat-awareness-month-2023-expert-roundup-bystander-engagement/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/national-insider-threat-awareness-month-2023-expert-roundup-bystander-engagement/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/national-insider-threat-awareness-month-2023-expert-roundup-bystander-engagement/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/55-back-to-school-tips-and-insights-for-a-great-year/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/55-back-to-school-tips-and-insights-for-a-great-year/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/55-back-to-school-tips-and-insights-for-a-great-year/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/55-back-to-school-tips-and-insights-for-a-great-year/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/55-back-to-school-tips-and-insights-for-a-great-year/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/object-storage-affordable-data-security-amid-cloud-flation-budget-constraints/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/object-storage-affordable-data-security-amid-cloud-flation-budget-constraints/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/object-storage-affordable-data-security-amid-cloud-flation-budget-constraints/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/object-storage-affordable-data-security-amid-cloud-flation-budget-constraints/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/object-storage-affordable-data-security-amid-cloud-flation-budget-constraints/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/cyberattackers-have-upped-their-game-have-a-recovery-plan-ready/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/cyberattackers-have-upped-their-game-have-a-recovery-plan-ready/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/cyberattackers-have-upped-their-game-have-a-recovery-plan-ready/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/cyberattackers-have-upped-their-game-have-a-recovery-plan-ready/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/cyberattackers-have-upped-their-game-have-a-recovery-plan-ready/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/a-last-line-of-defense-against-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/a-last-line-of-defense-against-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/a-last-line-of-defense-against-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/a-last-line-of-defense-against-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/a-last-line-of-defense-against-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/safer-internet-day-2023-19-experts-share-best-practices-for-online-safety/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/safer-internet-day-2023-19-experts-share-best-practices-for-online-safety/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/safer-internet-day-2023-19-experts-share-best-practices-for-online-safety/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/safer-internet-day-2023-19-experts-share-best-practices-for-online-safety/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/safer-internet-day-2023-19-experts-share-best-practices-for-online-safety/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/veeam-promises-to-swat-away-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/veeam-promises-to-swat-away-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/veeam-promises-to-swat-away-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/veeam-promises-to-swat-away-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/veeam-promises-to-swat-away-ransomware/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/elevate-your-schools-security-posture-as-2024-approaches/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/elevate-your-schools-security-posture-as-2024-approaches/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/elevate-your-schools-security-posture-as-2024-approaches/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/elevate-your-schools-security-posture-as-2024-approaches/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/elevate-your-schools-security-posture-as-2024-approaches/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/cloud-alone-isnt-enough-3-tips-to-improve-cybersecurity/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/cloud-alone-isnt-enough-3-tips-to-improve-cybersecurity/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/cloud-alone-isnt-enough-3-tips-to-improve-cybersecurity/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/cloud-alone-isnt-enough-3-tips-to-improve-cybersecurity/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/cloud-alone-isnt-enough-3-tips-to-improve-cybersecurity/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/object-first-launches-with-appliance-for-veeam-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/object-first-launches-with-appliance-for-veeam-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/object-first-launches-with-appliance-for-veeam-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/object-first-launches-with-appliance-for-veeam-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/object-first-launches-with-appliance-for-veeam-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/heres-what-your-breach-response-plan-might-be-missing/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/heres-what-your-breach-response-plan-might-be-missing/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/heres-what-your-breach-response-plan-might-be-missing/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/heres-what-your-breach-response-plan-might-be-missing/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/heres-what-your-breach-response-plan-might-be-missing/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/557-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/557-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/557-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/557-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/557-2/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/6-precautions-for-cios-to-consider-about-chatgpt/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/6-precautions-for-cios-to-consider-about-chatgpt/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/6-precautions-for-cios-to-consider-about-chatgpt/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/6-precautions-for-cios-to-consider-about-chatgpt/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/6-precautions-for-cios-to-consider-about-chatgpt/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/5-strategic-tech-trends-for-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/5-strategic-tech-trends-for-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/5-strategic-tech-trends-for-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/5-strategic-tech-trends-for-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/5-strategic-tech-trends-for-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/heard-on-the-street-9-11-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/heard-on-the-street-9-11-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/heard-on-the-street-9-11-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/heard-on-the-street-9-11-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/heard-on-the-street-9-11-2023/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/newsroom/in-the-news/newsroom/in-the-news/christophe-bertrand-and-ratmir-timashev-have-discussed-all-things-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/newsroom/in-the-news/newsroom/in-the-news/christophe-bertrand-and-ratmir-timashev-have-discussed-all-things-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/newsroom/in-the-news/newsroom/in-the-news/christophe-bertrand-and-ratmir-timashev-have-discussed-all-things-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/newsroom/in-the-news/newsroom/in-the-news/christophe-bertrand-and-ratmir-timashev-have-discussed-all-things-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/newsroom/in-the-news/newsroom/in-the-news/christophe-bertrand-and-ratmir-timashev-have-discussed-all-things-object-first/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/resources-tag/partner-case-study/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/resources-tag/partner-case-study/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/resources-tag/partner-case-study/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/resources-tag/partner-case-study/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/resources-tag/partner-case-study/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/resources-tag/case-study/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/resources-tag/case-study/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/resources-tag/case-study/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/resources-tag/case-study/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/resources-tag/case-study/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/office365-auth-response/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/office365-auth-response/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/office365-auth-response/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/office365-auth-response/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/office365-auth-response/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/resources/analyst-reports/why-object-storage-is-suitable-for-modern-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/resources/analyst-reports/why-object-storage-is-suitable-for-modern-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/resources/analyst-reports/why-object-storage-is-suitable-for-modern-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/resources/analyst-reports/why-object-storage-is-suitable-for-modern-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/resources/analyst-reports/why-object-storage-is-suitable-for-modern-backup-storage/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/author/ivan/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/author/ivan/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/author/ivan/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/author/ivan/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/author/ivan/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/author/user_of/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/author/user_of/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/author/user_of/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/author/user_of/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/author/user_of/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/join-partner-program/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/join-partner-program/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/join-partner-program/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/join-partner-program/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/join-partner-program/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ootbi-backup-appliance-for-hci/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/ootbi-backup-appliance-for-hci/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/ootbi-backup-appliance-for-hci/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/ootbi-backup-appliance-for-hci/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/ootbi-backup-appliance-for-hci/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ootbi-product-reviews/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/ootbi-product-reviews/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/ootbi-product-reviews/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/ootbi-product-reviews/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/ootbi-product-reviews/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/white_paper/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/white_paper/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/white_paper/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/white_paper/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/white_paper/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/guides/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/guides/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/guides/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/guides/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/guides/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/data-backup-definition-options-types-and-solutions/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/data-backup-definition-options-types-and-solutions/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/data-backup-definition-options-types-and-solutions/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/data-backup-definition-options-types-and-solutions/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/data-backup-definition-options-types-and-solutions/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/knowledge-base-category/data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/knowledge-base-category/data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/knowledge-base-category/data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/knowledge-base-category/data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/knowledge-base-category/data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/blog/view-all/file-storage-what-is-it/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/view-all/file-storage-what-is-it/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/blog/view-all/file-storage-what-is-it/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/blog/view-all/file-storage-what-is-it/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/blog/view-all/file-storage-what-is-it/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/request-live-demo-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/request-live-demo-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/request-live-demo-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/request-live-demo-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/request-live-demo-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/request-live-demo-test/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/request-live-demo-test/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/request-live-demo-test/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/request-live-demo-test/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/request-live-demo-test/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/author/matt-price/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/author/matt-price/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/author/matt-price/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/author/matt-price/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/author/matt-price/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/technical/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/technical/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/technical/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/technical/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/technical/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/hardware-backup-for-your-business-types-pros-cons/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/hardware-backup-for-your-business-types-pros-cons/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/hardware-backup-for-your-business-types-pros-cons/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/hardware-backup-for-your-business-types-pros-cons/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/hardware-backup-for-your-business-types-pros-cons/feed/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/contact-sales-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/contact-sales-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/contact-sales-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/contact-sales-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/contact-sales-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/how-to-buy-ootbi-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/how-to-buy-ootbi-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/how-to-buy-ootbi-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/how-to-buy-ootbi-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/how-to-buy-ootbi-tes/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/blog/ootbi-installation-guide/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/ootbi-installation-guide/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/blog/ootbi-installation-guide/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/blog/ootbi-installation-guide/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/blog/ootbi-installation-guide/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/blog/introducing-blog-series-related-to-zero-trust-and-data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/introducing-blog-series-related-to-zero-trust-and-data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/blog/introducing-blog-series-related-to-zero-trust-and-data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/blog/introducing-blog-series-related-to-zero-trust-and-data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/blog/introducing-blog-series-related-to-zero-trust-and-data-backup/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/section-blog/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/section-blog/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/section-blog/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/section-blog/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/section-blog/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/blog/how-to-implement-zero-trust-a-complete-guide/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/how-to-implement-zero-trust-a-complete-guide/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/blog/how-to-implement-zero-trust-a-complete-guide/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/blog/how-to-implement-zero-trust-a-complete-guide/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/blog/how-to-implement-zero-trust-a-complete-guide/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/blog/object-storage-vs-block-storage-vs-file-storage-full-comparison/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/object-storage-vs-block-storage-vs-file-storage-full-comparison/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/blog/object-storage-vs-block-storage-vs-file-storage-full-comparison/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/blog/object-storage-vs-block-storage-vs-file-storage-full-comparison/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/blog/object-storage-vs-block-storage-vs-file-storage-full-comparison/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/dpa/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/dpa/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/dpa/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/dpa/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/dpa/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/big-announcement-on-veeamon-event/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/big-announcement-on-veeamon-event/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/big-announcement-on-veeamon-event/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/big-announcement-on-veeamon-event/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/big-announcement-on-veeamon-event/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/installation/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/installation/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/installation/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/installation/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/installation/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/veeamon-2024-raffle/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/veeamon-2024-raffle/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/veeamon-2024-raffle/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/veeamon-2024-raffle/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/veeamon-2024-raffle/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/veeamon-2024-raffle/*",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/veeamon-2024-raffle/*",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/veeamon-2024-raffle/*",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/veeamon-2024-raffle/*",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/veeamon-2024-raffle/*",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/join-us-at-veeamon-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/join-us-at-veeamon-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/join-us-at-veeamon-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/join-us-at-veeamon-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/join-us-at-veeamon-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ootbi-for-veeam-webinar-may-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/ootbi-for-veeam-webinar-may-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/ootbi-for-veeam-webinar-may-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/ootbi-for-veeam-webinar-may-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/ootbi-for-veeam-webinar-may-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ootbi-for-veeam-webinar-session-may-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/ootbi-for-veeam-webinar-session-may-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/ootbi-for-veeam-webinar-session-may-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/ootbi-for-veeam-webinar-session-may-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/ootbi-for-veeam-webinar-session-may-2024/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/replace-your-hardware-with-ootbi/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/replace-your-hardware-with-ootbi/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/replace-your-hardware-with-ootbi/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/replace-your-hardware-with-ootbi/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/replace-your-hardware-with-ootbi/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ObjectFirstHelpHome.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ObjectFirstHelpHome.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ObjectFirstHelpHome.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ObjectFirstHelpHome.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ObjectFirstHelpHome.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Createnewcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Createnewcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Createnewcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Createnewcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Createnewcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Introduction.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Introduction.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Introduction.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Introduction.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Introduction.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/AccessingtheObjectFirstOotbiAppliance.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/AccessingtheObjectFirstOotbiAppliance.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/AccessingtheObjectFirstOotbiAppliance.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/AccessingtheObjectFirstOotbiAppliance.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/AccessingtheObjectFirstOotbiAppliance.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/CreatingS3bucket.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/CreatingS3bucket.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/CreatingS3bucket.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/CreatingS3bucket.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/CreatingS3bucket.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/SupportInformation.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/SupportInformation.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/SupportInformation.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/SupportInformation.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/SupportInformation.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ConnectingLocallyviaDisplayandKeyboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ConnectingLocallyviaDisplayandKeyboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ConnectingLocallyviaDisplayandKeyboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ConnectingLocallyviaDisplayandKeyboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ConnectingLocallyviaDisplayandKeyboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ObjectFirstOotbiapplianceconnectionandphysicalsetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ObjectFirstOotbiapplianceconnectionandphysicalsetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ObjectFirstOotbiapplianceconnectionandphysicalsetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ObjectFirstOotbiapplianceconnectionandphysicalsetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ObjectFirstOotbiapplianceconnectionandphysicalsetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ObjectFirstWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ObjectFirstWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ObjectFirstWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ObjectFirstWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ObjectFirstWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/LogIntotheRemoteConsoleusingIPMIconnection.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/LogIntotheRemoteConsoleusingIPMIconnection.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/LogIntotheRemoteConsoleusingIPMIconnection.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/LogIntotheRemoteConsoleusingIPMIconnection.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/LogIntotheRemoteConsoleusingIPMIconnection.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/FirstonsiteconfigurationstepsusingTextUserInterfaceTUI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/FirstonsiteconfigurationstepsusingTextUserInterfaceTUI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/FirstonsiteconfigurationstepsusingTextUserInterfaceTUI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/FirstonsiteconfigurationstepsusingTextUserInterfaceTUI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/FirstonsiteconfigurationstepsusingTextUserInterfaceTUI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ProxySetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ProxySetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ProxySetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ProxySetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ProxySetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ObjectFirstOotbiWebManagementConsoleDescription.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ObjectFirstOotbiWebManagementConsoleDescription.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ObjectFirstOotbiWebManagementConsoleDescription.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ObjectFirstOotbiWebManagementConsoleDescription.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ObjectFirstOotbiWebManagementConsoleDescription.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/GeneratinganS3AccessKey.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/GeneratinganS3AccessKey.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/GeneratinganS3AccessKey.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/GeneratinganS3AccessKey.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/GeneratinganS3AccessKey.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/MonitoringConfiguringEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/MonitoringConfiguringEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/MonitoringConfiguringEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/MonitoringConfiguringEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/MonitoringConfiguringEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/UpdateObjectFirstOotbiAppliance.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/UpdateObjectFirstOotbiAppliance.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/UpdateObjectFirstOotbiAppliance.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/UpdateObjectFirstOotbiAppliance.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/UpdateObjectFirstOotbiAppliance.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ObjectFirstQuickStartGuide.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ObjectFirstQuickStartGuide.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ObjectFirstQuickStartGuide.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ObjectFirstQuickStartGuide.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ObjectFirstQuickStartGuide.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Joinnodetotheexistingcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Joinnodetotheexistingcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Joinnodetotheexistingcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Joinnodetotheexistingcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Joinnodetotheexistingcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/IntegratingtheObjectFirstOotbiApplianceinabackupinfrastructure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/IntegratingtheObjectFirstOotbiApplianceinabackupinfrastructure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/IntegratingtheObjectFirstOotbiApplianceinabackupinfrastructure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/IntegratingtheObjectFirstOotbiApplianceinabackupinfrastructure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/IntegratingtheObjectFirstOotbiApplianceinabackupinfrastructure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowtoAcquiretheObjectFirstApplianceSerialNumber.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowtoAcquiretheObjectFirstApplianceSerialNumber.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowtoAcquiretheObjectFirstApplianceSerialNumber.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowtoAcquiretheObjectFirstApplianceSerialNumber.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowtoAcquiretheObjectFirstApplianceSerialNumber.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/SupportandTroubleshooting.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/SupportandTroubleshooting.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/SupportandTroubleshooting.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/SupportandTroubleshooting.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/SupportandTroubleshooting.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowtoCollectlogstosharewithsupport.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowtoCollectlogstosharewithsupport.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowtoCollectlogstosharewithsupport.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowtoCollectlogstosharewithsupport.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowtoCollectlogstosharewithsupport.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowtofileaSupportRequest.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowtofileaSupportRequest.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowtofileaSupportRequest.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowtofileaSupportRequest.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowtofileaSupportRequest.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ReleaseNotes.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ReleaseNotes.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ReleaseNotes.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ReleaseNotes.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ReleaseNotes.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ApplianceIPMIManagementinterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ApplianceIPMIManagementinterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ApplianceIPMIManagementinterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ApplianceIPMIManagementinterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ApplianceIPMIManagementinterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Dashboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Dashboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Dashboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Dashboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Dashboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Monitoring.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Monitoring.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Monitoring.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Monitoring.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Monitoring.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/S3Buckets.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/S3Buckets.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/S3Buckets.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/S3Buckets.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/S3Buckets.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/AdditionalSettings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/AdditionalSettings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/AdditionalSettings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/AdditionalSettings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/AdditionalSettings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Notificationsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Notificationsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Notificationsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Notificationsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Notificationsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/MonitoringtheSystem.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/MonitoringtheSystem.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/MonitoringtheSystem.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/MonitoringtheSystem.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/MonitoringtheSystem.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Installingupdates.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Installingupdates.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Installingupdates.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Installingupdates.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Installingupdates.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/AboutObjectFirstOotbi.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/AboutObjectFirstOotbi.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/AboutObjectFirstOotbi.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/AboutObjectFirstOotbi.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/AboutObjectFirstOotbi.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ObjectFirstUserInterfaceOverviewTextUserInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ObjectFirstUserInterfaceOverviewTextUserInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ObjectFirstUserInterfaceOverviewTextUserInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ObjectFirstUserInterfaceOverviewTextUserInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ObjectFirstUserInterfaceOverviewTextUserInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/CreateaCluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/CreateaCluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/CreateaCluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/CreateaCluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/CreateaCluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Cluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Cluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Cluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Cluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Cluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Security.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Security.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Security.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Security.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Security.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Eventsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Eventsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Eventsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Eventsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Eventsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Networkview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Networkview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Networkview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Networkview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Networkview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/SecuretheSystem.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/SecuretheSystem.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/SecuretheSystem.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/SecuretheSystem.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/SecuretheSystem.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/EnableTwofactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/EnableTwofactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/EnableTwofactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/EnableTwofactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/EnableTwofactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Regeneratingtherecoverycodes.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Regeneratingtherecoverycodes.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Regeneratingtherecoverycodes.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Regeneratingtherecoverycodes.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Regeneratingtherecoverycodes.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/LoginProcesswith2FAenabled.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/LoginProcesswith2FAenabled.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/LoginProcesswith2FAenabled.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/LoginProcesswith2FAenabled.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/LoginProcesswith2FAenabled.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/EnableEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/EnableEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/EnableEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/EnableEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/EnableEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ObjectFirstUserGuide.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ObjectFirstUserGuide.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ObjectFirstUserGuide.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ObjectFirstUserGuide.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ObjectFirstUserGuide.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Connectivitydiagramandimplementationscenarios.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Connectivitydiagramandimplementationscenarios.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Connectivitydiagramandimplementationscenarios.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Connectivitydiagramandimplementationscenarios.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Connectivitydiagramandimplementationscenarios.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Events.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Events.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Events.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Events.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Events.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Alertsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Alertsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Alertsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Alertsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Alertsview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Settings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Settings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Settings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Settings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Settings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Securityview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Securityview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Securityview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Securityview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Securityview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ConfiguringMultiFactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ConfiguringMultiFactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ConfiguringMultiFactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ConfiguringMultiFactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ConfiguringMultiFactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ConfiguringObjectFirstasSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ConfiguringObjectFirstasSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ConfiguringObjectFirstasSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ConfiguringObjectFirstasSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ConfiguringObjectFirstasSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ConfiguringObjectFirstStorage.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ConfiguringObjectFirstStorage.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ConfiguringObjectFirstStorage.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ConfiguringObjectFirstStorage.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ConfiguringObjectFirstStorage.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/GeneratingS3AccessKeys.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/GeneratingS3AccessKeys.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/GeneratingS3AccessKeys.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/GeneratingS3AccessKeys.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/GeneratingS3AccessKeys.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/MonitoringViewofObjectFirstOotbiApplianceWebInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/MonitoringViewofObjectFirstOotbiApplianceWebInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/MonitoringViewofObjectFirstOotbiApplianceWebInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/MonitoringViewofObjectFirstOotbiApplianceWebInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/MonitoringViewofObjectFirstOotbiApplianceWebInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Alertmessages.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Alertmessages.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Alertmessages.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Alertmessages.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Alertmessages.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ResettingthePassword.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ResettingthePassword.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ResettingthePassword.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ResettingthePassword.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ResettingthePassword.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/PhysicalSetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/PhysicalSetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/PhysicalSetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/PhysicalSetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/PhysicalSetup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Joiningthenodetotheexistingcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Joiningthenodetotheexistingcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Joiningthenodetotheexistingcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Joiningthenodetotheexistingcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Joiningthenodetotheexistingcluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ObjectFirstUserInterfaceOverviewWebUserInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ObjectFirstUserInterfaceOverviewWebUserInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ObjectFirstUserInterfaceOverviewWebUserInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ObjectFirstUserInterfaceOverviewWebUserInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ObjectFirstUserInterfaceOverviewWebUserInterface.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Alerts.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Alerts.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Alerts.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Alerts.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Alerts.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Account.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Account.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Account.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Account.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Account.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/TimeZoneview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/TimeZoneview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/TimeZoneview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/TimeZoneview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/TimeZoneview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ClusterUpdateview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ClusterUpdateview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ClusterUpdateview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ClusterUpdateview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ClusterUpdateview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/PowerControlView.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/PowerControlView.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/PowerControlView.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/PowerControlView.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/PowerControlView.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/DisablingTwofactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/DisablingTwofactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/DisablingTwofactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/DisablingTwofactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/DisablingTwofactorAuthentication.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/BucketConceptOverview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/BucketConceptOverview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/BucketConceptOverview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/BucketConceptOverview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/BucketConceptOverview.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/CreatingObjectFirstS3Buckets.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/CreatingObjectFirstS3Buckets.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/CreatingObjectFirstS3Buckets.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/CreatingObjectFirstS3Buckets.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/CreatingObjectFirstS3Buckets.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/SystemManagement.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/SystemManagement.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/SystemManagement.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/SystemManagement.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/SystemManagement.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/EnablingSSHAccess.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/EnablingSSHAccess.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/EnablingSSHAccess.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/EnablingSSHAccess.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/EnablingSSHAccess.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ConfiguringVeeamBackupandReplicationwithObjectFirst.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ConfiguringVeeamBackupandReplicationwithObjectFirst.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ConfiguringVeeamBackupandReplicationwithObjectFirst.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ConfiguringVeeamBackupandReplicationwithObjectFirst.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ConfiguringVeeamBackupandReplicationwithObjectFirst.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/BackupVMstoObjectFirstRepository.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/BackupVMstoObjectFirstRepository.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/BackupVMstoObjectFirstRepository.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/BackupVMstoObjectFirstRepository.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/BackupVMstoObjectFirstRepository.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/GenerateSSLcertificates.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/GenerateSSLcertificates.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/GenerateSSLcertificates.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/GenerateSSLcertificates.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/GenerateSSLcertificates.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ConfiguretheS3StoragebasedrepositoryforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ConfiguretheS3StoragebasedrepositoryforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ConfiguretheS3StoragebasedrepositoryforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ConfiguretheS3StoragebasedrepositoryforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ConfiguretheS3StoragebasedrepositoryforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ChangingNetworkSettings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ChangingNetworkSettings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ChangingNetworkSettings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ChangingNetworkSettings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ChangingNetworkSettings.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ObjectFirstApplianceasaPrimaryBackupRepositoryforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ObjectFirstApplianceasaPrimaryBackupRepositoryforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ObjectFirstApplianceasaPrimaryBackupRepositoryforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ObjectFirstApplianceasaPrimaryBackupRepositoryforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ObjectFirstApplianceasaPrimaryBackupRepositoryforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/BackupOffloadProcedure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/BackupOffloadProcedure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/BackupOffloadProcedure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/BackupOffloadProcedure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/BackupOffloadProcedure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ConfigureObjectFirstasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ConfigureObjectFirstasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ConfigureObjectFirstasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ConfigureObjectFirstasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ConfigureObjectFirstasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/RestoretheBackupfromtheSOBRcapacitytier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/RestoretheBackupfromtheSOBRcapacitytier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/RestoretheBackupfromtheSOBRcapacitytier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/RestoretheBackupfromtheSOBRcapacitytier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/RestoretheBackupfromtheSOBRcapacitytier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowtoReplaceaDisk.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowtoReplaceaDisk.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowtoReplaceaDisk.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowtoReplaceaDisk.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowtoReplaceaDisk.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Instantrecoveryfrombackup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Instantrecoveryfrombackup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Instantrecoveryfrombackup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Instantrecoveryfrombackup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Instantrecoveryfrombackup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Evaluatingtheperformanceofbackupandrestoreoperations.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Evaluatingtheperformanceofbackupandrestoreoperations.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Evaluatingtheperformanceofbackupandrestoreoperations.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Evaluatingtheperformanceofbackupandrestoreoperations.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Evaluatingtheperformanceofbackupandrestoreoperations.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/InstantrecoveryfrombackuponaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/InstantrecoveryfrombackuponaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/InstantrecoveryfrombackuponaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/InstantrecoveryfrombackuponaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/InstantrecoveryfrombackuponaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/EvaluatingtheperformanceofbackupandrestoreoperationsObjectFirstOotbiAsPerformanceTierofSoBR.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/EvaluatingtheperformanceofbackupandrestoreoperationsObjectFirstOotbiAsPerformanceTierofSoBR.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/EvaluatingtheperformanceofbackupandrestoreoperationsObjectFirstOotbiAsPerformanceTierofSoBR.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/EvaluatingtheperformanceofbackupandrestoreoperationsObjectFirstOotbiAsPerformanceTierofSoBR.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/EvaluatingtheperformanceofbackupandrestoreoperationsObjectFirstOotbiAsPerformanceTierofSoBR.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/BackupVMstoObjectFirstRepositoryasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/BackupVMstoObjectFirstRepositoryasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/BackupVMstoObjectFirstRepositoryasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/BackupVMstoObjectFirstRepositoryasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/BackupVMstoObjectFirstRepositoryasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Restorefrombackup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Restorefrombackup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Restorefrombackup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Restorefrombackup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Restorefrombackup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ObjectFirstApplianceasaPerformanceTierforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ObjectFirstApplianceasaPerformanceTierforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ObjectFirstApplianceasaPerformanceTierforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ObjectFirstApplianceasaPerformanceTierforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ObjectFirstApplianceasaPerformanceTierforVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/InstantrecoveryfromtheSOBRcapacitytier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/InstantrecoveryfromtheSOBRcapacitytier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/InstantrecoveryfromtheSOBRcapacitytier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/InstantrecoveryfromtheSOBRcapacitytier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/InstantrecoveryfromtheSOBRcapacitytier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/BackupoffloadtoObjectFirstOotbiasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/BackupoffloadtoObjectFirstOotbiasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/BackupoffloadtoObjectFirstOotbiasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/BackupoffloadtoObjectFirstOotbiasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/BackupoffloadtoObjectFirstOotbiasaSOBRCapacityTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ConfiguringObjectFirstasaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ConfiguringObjectFirstasaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ConfiguringObjectFirstasaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ConfiguringObjectFirstasaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ConfiguringObjectFirstasaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/BackupVMstoObjectFirstRepositoryasaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/BackupVMstoObjectFirstRepositoryasaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/BackupVMstoObjectFirstRepositoryasaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/BackupVMstoObjectFirstRepositoryasaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/BackupVMstoObjectFirstRepositoryasaSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/RestorefrombackuponSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/RestorefrombackuponSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/RestorefrombackuponSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/RestorefrombackuponSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/RestorefrombackuponSOBRPerformanceTier.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ObjectFirstastheCapacityTierofSOBRinVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ObjectFirstastheCapacityTierofSOBRinVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ObjectFirstastheCapacityTierofSOBRinVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ObjectFirstastheCapacityTierofSOBRinVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ObjectFirstastheCapacityTierofSOBRinVeeamBackupReplicationv12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/EvaluatingtheperformanceofbackupandrestoreoperationsObjectFirstOotbiAsCapacityTierofSoBR.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/EvaluatingtheperformanceofbackupandrestoreoperationsObjectFirstOotbiAsCapacityTierofSoBR.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/EvaluatingtheperformanceofbackupandrestoreoperationsObjectFirstOotbiAsCapacityTierofSoBR.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/EvaluatingtheperformanceofbackupandrestoreoperationsObjectFirstOotbiAsCapacityTierofSoBR.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/EvaluatingtheperformanceofbackupandrestoreoperationsObjectFirstOotbiAsCapacityTierofSoBR.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/ObjectFirstHowToGuides.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/ObjectFirstHowToGuides.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/ObjectFirstHowToGuides.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/ObjectFirstHowToGuides.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/ObjectFirstHowToGuides.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowtoRequestAuthorizationCode.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowtoRequestAuthorizationCode.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowtoRequestAuthorizationCode.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowtoRequestAuthorizationCode.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowtoRequestAuthorizationCode.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/CollectingthelogsinTextUserInterfaceTUI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/CollectingthelogsinTextUserInterfaceTUI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/CollectingthelogsinTextUserInterfaceTUI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/CollectingthelogsinTextUserInterfaceTUI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/CollectingthelogsinTextUserInterfaceTUI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowtoAddnodetocluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowtoAddnodetocluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowtoAddnodetocluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowtoAddnodetocluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowtoAddnodetocluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowtoRestartshutdownprocedure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowtoRestartshutdownprocedure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowtoRestartshutdownprocedure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowtoRestartshutdownprocedure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowtoRestartshutdownprocedure.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowtoConfigureBackupVMstoObjectFirstRepositorywithVeeamBackupandReplicationV12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowtoConfigureBackupVMstoObjectFirstRepositorywithVeeamBackupandReplicationV12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowtoConfigureBackupVMstoObjectFirstRepositorywithVeeamBackupandReplicationV12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowtoConfigureBackupVMstoObjectFirstRepositorywithVeeamBackupandReplicationV12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowtoConfigureBackupVMstoObjectFirstRepositorywithVeeamBackupandReplicationV12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/LogintotheremoteconsoleusingtheIPMIconnection.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/LogintotheremoteconsoleusingtheIPMIconnection.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/LogintotheremoteconsoleusingtheIPMIconnection.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/LogintotheremoteconsoleusingtheIPMIconnection.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/LogintotheremoteconsoleusingtheIPMIconnection.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowtoConfigureObjectFirstbasedrepositoryinVBR12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowtoConfigureObjectFirstbasedrepositoryinVBR12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowtoConfigureObjectFirstbasedrepositoryinVBR12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowtoConfigureObjectFirstbasedrepositoryinVBR12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowtoConfigureObjectFirstbasedrepositoryinVBR12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/CollectinglogsinObjectFirstWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/CollectinglogsinObjectFirstWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/CollectinglogsinObjectFirstWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/CollectinglogsinObjectFirstWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/CollectinglogsinObjectFirstWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowtoChangeClusterorNodeIPAddress.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowtoChangeClusterorNodeIPAddress.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowtoChangeClusterorNodeIPAddress.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowtoChangeClusterorNodeIPAddress.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowtoChangeClusterorNodeIPAddress.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/RebootShutdownthecluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/RebootShutdownthecluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/RebootShutdownthecluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/RebootShutdownthecluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/RebootShutdownthecluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/RebootShutdownanindividualObjectFirstnode.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/RebootShutdownanindividualObjectFirstnode.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/RebootShutdownanindividualObjectFirstnode.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/RebootShutdownanindividualObjectFirstnode.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/RebootShutdownanindividualObjectFirstnode.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowtoRemovenodefromthecluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowtoRemovenodefromthecluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowtoRemovenodefromthecluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowtoRemovenodefromthecluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowtoRemovenodefromthecluster.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Connectinglocallyviadisplayandakeyboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Connectinglocallyviadisplayandakeyboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Connectinglocallyviadisplayandakeyboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Connectinglocallyviadisplayandakeyboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Connectinglocallyviadisplayandakeyboard.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowtoResetNodeData.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowtoResetNodeData.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowtoResetNodeData.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowtoResetNodeData.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowtoResetNodeData.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Logout.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Logout.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Logout.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Logout.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Logout.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowToImplementIAMonOotbiusingAWSCLI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowToImplementIAMonOotbiusingAWSCLI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowToImplementIAMonOotbiusingAWSCLI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowToImplementIAMonOotbiusingAWSCLI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowToImplementIAMonOotbiusingAWSCLI.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowtoImplementIAMforAgentBackupswithVeeamBackupandReplication12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowtoImplementIAMforAgentBackupswithVeeamBackupandReplication12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowtoImplementIAMforAgentBackupswithVeeamBackupandReplication12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowtoImplementIAMforAgentBackupswithVeeamBackupandReplication12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowtoImplementIAMforAgentBackupswithVeeamBackupandReplication12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/HowtoImplementIAMforCloudConnectwithVeeamBackupandReplication12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/HowtoImplementIAMforCloudConnectwithVeeamBackupandReplication12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/HowtoImplementIAMforCloudConnectwithVeeamBackupandReplication12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/HowtoImplementIAMforCloudConnectwithVeeamBackupandReplication12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/HowtoImplementIAMforCloudConnectwithVeeamBackupandReplication12.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/CollectinglogsinObjectWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/CollectinglogsinObjectWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/CollectinglogsinObjectWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/CollectinglogsinObjectWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/CollectinglogsinObjectWebManagementConsole.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/Object-First-Help-Home_881361025.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/Object-First-Help-Home_881361025.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/Object-First-Help-Home_881361025.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/Object-First-Help-Home_881361025.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/Object-First-Help-Home_881361025.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/development.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/development.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/development.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/development.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/development.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/EnablingEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/EnablingEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/EnablingEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/EnablingEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/EnablingEmailNotifications.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/GeneratingAccessKey.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/GeneratingAccessKey.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/GeneratingAccessKey.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/GeneratingAccessKey.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/GeneratingAccessKey.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/help/setup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/help/setup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/help/setup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/help/setup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/help/setup.html",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2022/07/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2022/07/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2022/07/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2022/07/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2022/07/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2022/07/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2022/07/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2022/07/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2022/07/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2022/07/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2022/07/Ootbi-by-Object-First-Datasheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2022/07/Ootbi-by-Object-First-Datasheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2022/07/Ootbi-by-Object-First-Datasheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2022/07/Ootbi-by-Object-First-Datasheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2022/07/Ootbi-by-Object-First-Datasheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2022/12/ESG-Technical-Review-Object-First-Nov-2022-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2022/12/ESG-Technical-Review-Object-First-Nov-2022-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2022/12/ESG-Technical-Review-Object-First-Nov-2022-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2022/12/ESG-Technical-Review-Object-First-Nov-2022-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2022/12/ESG-Technical-Review-Object-First-Nov-2022-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2022/12/Object-First_white-paper_4-reasons.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2022/12/Object-First_white-paper_4-reasons.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2022/12/Object-First_white-paper_4-reasons.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2022/12/Object-First_white-paper_4-reasons.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2022/12/Object-First_white-paper_4-reasons.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/02/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/02/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/02/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/02/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/02/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/03/ESG-Showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/03/ESG-Showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/03/ESG-Showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/03/ESG-Showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/03/ESG-Showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/04/Object-First-Technical-Paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/04/Object-First-Technical-Paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/04/Object-First-Technical-Paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/04/Object-First-Technical-Paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/04/Object-First-Technical-Paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/05/Esg-final-To-Partner-showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/05/Esg-final-To-Partner-showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/05/Esg-final-To-Partner-showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/05/Esg-final-To-Partner-showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/05/Esg-final-To-Partner-showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/05/Esg-final-To-Partner-showcase-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/05/Esg-final-To-Partner-showcase-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/05/Esg-final-To-Partner-showcase-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/05/Esg-final-To-Partner-showcase-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/05/Esg-final-To-Partner-showcase-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/05/US49949122.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/05/US49949122.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/05/US49949122.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/05/US49949122.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/05/US49949122.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/06/Dynata-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/06/Dynata-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/06/Dynata-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/06/Dynata-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/06/Dynata-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/06/ESG-Infographic.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/06/ESG-Infographic.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/06/ESG-Infographic.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/06/ESG-Infographic.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/06/ESG-Infographic.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/06/Object-First-Research-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/06/Object-First-Research-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/06/Object-First-Research-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/06/Object-First-Research-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/06/Object-First-Research-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/06/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/06/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/06/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/06/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/06/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/10/Centerbase-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/10/Centerbase-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/10/Centerbase-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/10/Centerbase-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/10/Centerbase-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/11/CM-branded-Ootbi-white-paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/11/CM-branded-Ootbi-white-paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/11/CM-branded-Ootbi-white-paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/11/CM-branded-Ootbi-white-paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/11/CM-branded-Ootbi-white-paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Ootbi-Installation-Guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Ootbi-Installation-Guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Ootbi-Installation-Guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Ootbi-Installation-Guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Ootbi-Installation-Guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/11/CM-branded-Ootbi-white-paper-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/11/CM-branded-Ootbi-white-paper-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/11/CM-branded-Ootbi-white-paper-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/11/CM-branded-Ootbi-white-paper-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/11/CM-branded-Ootbi-white-paper-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Ootbi_Guide-ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Ootbi_Guide-ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Ootbi_Guide-ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Ootbi_Guide-ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Ootbi_Guide-ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Ootbi_Guide-ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Ootbi_Guide-ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Ootbi_Guide-ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Ootbi_Guide-ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Ootbi_Guide-ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Ootbi_Guide-FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Ootbi_Guide-FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Ootbi_Guide-FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Ootbi_Guide-FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Ootbi_Guide-FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Ootbi_Guide-PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Ootbi_Guide-PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Ootbi_Guide-PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Ootbi_Guide-PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Ootbi_Guide-PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Ootbi_Guide-PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Ootbi_Guide-PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Ootbi_Guide-PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Ootbi_Guide-PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Ootbi_Guide-PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Ootbi_Guide_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Ootbi_Guide_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Ootbi_Guide_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Ootbi_Guide_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Ootbi_Guide_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Ootbi_Guide_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Ootbi_Guide_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Ootbi_Guide_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Ootbi_Guide_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Ootbi_Guide_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Ootbi_Guide_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Ootbi_Guide_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Ootbi_Guide_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Ootbi_Guide_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Ootbi_Guide_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Ootbi_Guide_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Ootbi_Guide_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Ootbi_Guide_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Ootbi_Guide_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Ootbi_Guide_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Ootbi_Guide_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Ootbi_Guide_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Ootbi_Guide_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Ootbi_Guide_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Ootbi_Guide_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Ootbi_Guide_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Ootbi_Guide_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Ootbi_Guide_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Ootbi_Guide_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Ootbi_Guide_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Ootbi_Guide_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Ootbi_Guide_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Ootbi_Guide_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Ootbi_Guide_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Ootbi_Guide_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/SFAQ-One-Pager_ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/SFAQ-One-Pager_ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/SFAQ-One-Pager_ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/SFAQ-One-Pager_ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/SFAQ-One-Pager_ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager-EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager-EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager-EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager-EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager-EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE-2.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE-2.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE-2.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE-2.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager_DE-2.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager_EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager_EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager_EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager_EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager_EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager_ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager_ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager_ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager_ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager_ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager_FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager_FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager_FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager_FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager_FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager_PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager_PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager_PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager_PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager_PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager_PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager_PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager_PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager_PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager_PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Support-FAQ-One-Pager_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Support-FAQ-One-Pager_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Support-FAQ-One-Pager_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Support-FAQ-One-Pager_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Support-FAQ-One-Pager_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2023/12/Zero-Trust-and-Secure-Backup-Storage.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2023/12/Zero-Trust-and-Secure-Backup-Storage.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2023/12/Zero-Trust-and-Secure-Backup-Storage.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2023/12/Zero-Trust-and-Secure-Backup-Storage.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2023/12/Zero-Trust-and-Secure-Backup-Storage.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wp-content/uploads/2024/01/Prodatix-Partner-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/wp-content/uploads/2024/01/Prodatix-Partner-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/wp-content/uploads/2024/01/Prodatix-Partner-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/wp-content/uploads/2024/01/Prodatix-Partner-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/wp-content/uploads/2024/01/Prodatix-Partner-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2022/07/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2022/07/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2022/07/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2022/07/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2022/07/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2022/07/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2022/07/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2022/07/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2022/07/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2022/07/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2022/07/Ootbi-by-Object-First-Datasheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2022/07/Ootbi-by-Object-First-Datasheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2022/07/Ootbi-by-Object-First-Datasheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2022/07/Ootbi-by-Object-First-Datasheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2022/07/Ootbi-by-Object-First-Datasheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2022/12/ESG-Technical-Review-Object-First-Nov-2022-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2022/12/ESG-Technical-Review-Object-First-Nov-2022-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2022/12/ESG-Technical-Review-Object-First-Nov-2022-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2022/12/ESG-Technical-Review-Object-First-Nov-2022-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2022/12/ESG-Technical-Review-Object-First-Nov-2022-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2022/12/Object-First_white-paper_4-reasons.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2022/12/Object-First_white-paper_4-reasons.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2022/12/Object-First_white-paper_4-reasons.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2022/12/Object-First_white-paper_4-reasons.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2022/12/Object-First_white-paper_4-reasons.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/02/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/02/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/02/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/02/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/02/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/03/ESG-Showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/03/ESG-Showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/03/ESG-Showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/03/ESG-Showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/03/ESG-Showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/04/Object-First-Technical-Paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/04/Object-First-Technical-Paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/04/Object-First-Technical-Paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/04/Object-First-Technical-Paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/04/Object-First-Technical-Paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/05/Esg-final-To-Partner-showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/05/Esg-final-To-Partner-showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/05/Esg-final-To-Partner-showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/05/Esg-final-To-Partner-showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/05/Esg-final-To-Partner-showcase.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/05/Esg-final-To-Partner-showcase-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/05/Esg-final-To-Partner-showcase-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/05/Esg-final-To-Partner-showcase-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/05/Esg-final-To-Partner-showcase-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/05/Esg-final-To-Partner-showcase-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/05/US49949122.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/05/US49949122.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/05/US49949122.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/05/US49949122.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/05/US49949122.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/06/Dynata-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/06/Dynata-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/06/Dynata-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/06/Dynata-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/06/Dynata-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/06/ESG-Infographic.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/06/ESG-Infographic.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/06/ESG-Infographic.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/06/ESG-Infographic.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/06/ESG-Infographic.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/06/Object-First-Research-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/06/Object-First-Research-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/06/Object-First-Research-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/06/Object-First-Research-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/06/Object-First-Research-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/06/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/06/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/06/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/06/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/06/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/08/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/08/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/08/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/08/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/08/Anonymous-Case-Study_four-year-private-university.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/10/Centerbase-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/10/Centerbase-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/10/Centerbase-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/10/Centerbase-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/10/Centerbase-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/11/CM-branded-Ootbi-white-paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/11/CM-branded-Ootbi-white-paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/11/CM-branded-Ootbi-white-paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/11/CM-branded-Ootbi-white-paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/11/CM-branded-Ootbi-white-paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/11/CM-branded-Ootbi-white-paper-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/11/CM-branded-Ootbi-white-paper-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/11/CM-branded-Ootbi-white-paper-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/11/CM-branded-Ootbi-white-paper-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/11/CM-branded-Ootbi-white-paper-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Ootbi-Installation-Guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Ootbi-Installation-Guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Ootbi-Installation-Guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Ootbi-Installation-Guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Ootbi-Installation-Guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Ootbi_Guide-ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Ootbi_Guide-ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Ootbi_Guide-ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Ootbi_Guide-ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Ootbi_Guide-ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Ootbi_Guide-ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Ootbi_Guide-ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Ootbi_Guide-ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Ootbi_Guide-ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Ootbi_Guide-ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Ootbi_Guide-FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Ootbi_Guide-FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Ootbi_Guide-FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Ootbi_Guide-FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Ootbi_Guide-FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Ootbi_Guide-PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Ootbi_Guide-PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Ootbi_Guide-PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Ootbi_Guide-PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Ootbi_Guide-PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Ootbi_Guide-PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Ootbi_Guide-PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Ootbi_Guide-PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Ootbi_Guide-PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Ootbi_Guide-PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Ootbi_Guide_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Ootbi_Guide_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Ootbi_Guide_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Ootbi_Guide_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Ootbi_Guide_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Ootbi_Guide_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Ootbi_Guide_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Ootbi_Guide_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Ootbi_Guide_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Ootbi_Guide_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Ootbi_Guide_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Ootbi_Guide_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Ootbi_Guide_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Ootbi_Guide_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Ootbi_Guide_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Ootbi_Guide_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Ootbi_Guide_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Ootbi_Guide_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Ootbi_Guide_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Ootbi_Guide_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Ootbi_Guide_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Ootbi_Guide_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Ootbi_Guide_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Ootbi_Guide_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Ootbi_Guide_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Ootbi_Guide_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Ootbi_Guide_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Ootbi_Guide_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Ootbi_Guide_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Ootbi_Guide_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Ootbi_Guide_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Ootbi_Guide_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Ootbi_Guide_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Ootbi_Guide_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Ootbi_Guide_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/SFAQ-One-Pager_ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/SFAQ-One-Pager_ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/SFAQ-One-Pager_ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/SFAQ-One-Pager_ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/SFAQ-One-Pager_ES.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager-EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager-EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager-EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager-EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager-EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager_DA.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager_DE.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager_DE-1.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager_DE-2.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager_DE-2.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager_DE-2.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager_DE-2.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager_DE-2.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager_EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager_EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager_EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager_EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager_EN.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager_ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager_ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager_ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager_ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager_ES-LAT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager_FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager_FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager_FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager_FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager_FI.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager_FR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager_NL.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager_NO.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager_PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager_PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager_PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager_PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager_PT.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager_PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager_PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager_PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager_PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager_PT-BR.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Support-FAQ-One-Pager_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Support-FAQ-One-Pager_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Support-FAQ-One-Pager_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Support-FAQ-One-Pager_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Support-FAQ-One-Pager_SV.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2023/12/Zero-Trust-and-Secure-Backup-Storage.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2023/12/Zero-Trust-and-Secure-Backup-Storage.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2023/12/Zero-Trust-and-Secure-Backup-Storage.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2023/12/Zero-Trust-and-Secure-Backup-Storage.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2023/12/Zero-Trust-and-Secure-Backup-Storage.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uploads/2024/01/Prodatix-Partner-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/uploads/2024/01/Prodatix-Partner-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/uploads/2024/01/Prodatix-Partner-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/uploads/2024/01/Prodatix-Partner-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/uploads/2024/01/Prodatix-Partner-Case-Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/4_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/4_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/4_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/4_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/4_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/pdf/4_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/pdf/4_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/pdf/4_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/pdf/4_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/pdf/4_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/3_Reasons_Object_First_is_Best_for_Veeam_white_paper27_11_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/3_Reasons_Object_First_is_Best_for_Veeam_white_paper27_11_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/3_Reasons_Object_First_is_Best_for_Veeam_white_paper27_11_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/3_Reasons_Object_First_is_Best_for_Veeam_white_paper27_11_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/3_Reasons_Object_First_is_Best_for_Veeam_white_paper27_11_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/4_Reasons_Object_First_is_Best_for_Veeam_white_paper14_02_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/4_Reasons_Object_First_is_Best_for_Veeam_white_paper14_02_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/4_Reasons_Object_First_is_Best_for_Veeam_white_paper14_02_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/4_Reasons_Object_First_is_Best_for_Veeam_white_paper14_02_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/4_Reasons_Object_First_is_Best_for_Veeam_white_paper14_02_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/4_Reasons_Object_First_is_Best_for_Veeam_white_paper16_01_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/4_Reasons_Object_First_is_Best_for_Veeam_white_paper16_01_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/4_Reasons_Object_First_is_Best_for_Veeam_white_paper16_01_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/4_Reasons_Object_First_is_Best_for_Veeam_white_paper16_01_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/4_Reasons_Object_First_is_Best_for_Veeam_white_paper16_01_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/4_Reasons_Object_First_is_Best_for_Veeam_white_paper26_06_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/4_Reasons_Object_First_is_Best_for_Veeam_white_paper26_06_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/4_Reasons_Object_First_is_Best_for_Veeam_white_paper26_06_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/4_Reasons_Object_First_is_Best_for_Veeam_white_paper26_06_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/4_Reasons_Object_First_is_Best_for_Veeam_white_paper26_06_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/4_Reasons_Object_First_is_Best_for_Veeam_white_paper31_07_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/4_Reasons_Object_First_is_Best_for_Veeam_white_paper31_07_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/4_Reasons_Object_First_is_Best_for_Veeam_white_paper31_07_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/4_Reasons_Object_First_is_Best_for_Veeam_white_paper31_07_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/4_Reasons_Object_First_is_Best_for_Veeam_white_paper31_07_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/5-reasons-object-first-is-best-for-veeam.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/5-reasons-object-first-is-best-for-veeam.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/5-reasons-object-first-is-best-for-veeam.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/5-reasons-object-first-is-best-for-veeam.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/5-reasons-object-first-is-best-for-veeam.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/5-reasons-object-first-is-best-for-veeam27_06_2022.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/5-reasons-object-first-is-best-for-veeam27_06_2022.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/5-reasons-object-first-is-best-for-veeam27_06_2022.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/5-reasons-object-first-is-best-for-veeam27_06_2022.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/5-reasons-object-first-is-best-for-veeam27_06_2022.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/Zero_Trust_and_Secure_Backup_Storage_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/Zero_Trust_and_Secure_Backup_Storage_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/Zero_Trust_and_Secure_Backup_Storage_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/Zero_Trust_and_Secure_Backup_Storage_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/Zero_Trust_and_Secure_Backup_Storage_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/Zero Trust Data Resilience Download Research.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/Zero Trust Data Resilience Download Research.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/Zero Trust Data Resilience Download Research.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/Zero Trust Data Resilience Download Research.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/Zero Trust Data Resilience Download Research.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/zero-trust-data-resilience-download-research.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/zero-trust-data-resilience-download-research.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/zero-trust-data-resilience-download-research.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/zero-trust-data-resilience-download-research.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/zero-trust-data-resilience-download-research.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/zero-trust-data-resilience-download-whitepaper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/zero-trust-data-resilience-download-whitepaper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/zero-trust-data-resilience-download-whitepaper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/zero-trust-data-resilience-download-whitepaper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/zero-trust-data-resilience-download-whitepaper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/CM_Waident_Ootbi_Case.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/CM_Waident_Ootbi_Case.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/CM_Waident_Ootbi_Case.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/CM_Waident_Ootbi_Case.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/CM_Waident_Ootbi_Case.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ransomware-survival-guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/ransomware-survival-guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/ransomware-survival-guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/ransomware-survival-guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/ransomware-survival-guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/3_Reasons_Object_First_is_Best_for_Veeam_white_paper.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/3_Reasons_Object_First_is_Best_for_Veeam_white_paper05_12_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/3_Reasons_Object_First_is_Best_for_Veeam_white_paper05_12_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/3_Reasons_Object_First_is_Best_for_Veeam_white_paper05_12_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/3_Reasons_Object_First_is_Best_for_Veeam_white_paper05_12_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/3_Reasons_Object_First_is_Best_for_Veeam_white_paper05_12_2023.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/5-reasons-object-first-is-best-for-veeam27_06_2022",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/5-reasons-object-first-is-best-for-veeam27_06_2022",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/5-reasons-object-first-is-best-for-veeam27_06_2022",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/5-reasons-object-first-is-best-for-veeam27_06_2022",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/5-reasons-object-first-is-best-for-veeam27_06_2022",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/Mirazon_Case_Study.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/pdf/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/pdf/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/pdf/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/pdf/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/pdf/Object-First-data-sheet.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/pdf/Object-First-data-sheet_de.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/pdf/Object-First-data-sheet_de.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/pdf/Object-First-data-sheet_de.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/pdf/Object-First-data-sheet_de.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/pdf/Object-First-data-sheet_de.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/pdf/Object-First-data-sheet_fr.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/pdf/Object-First-data-sheet_fr.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/pdf/Object-First-data-sheet_fr.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/pdf/Object-First-data-sheet_fr.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/pdf/Object-First-data-sheet_fr.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/pdf/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/pdf/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/pdf/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr/pdf/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es/pdf/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]