import type { Directive } from 'vue';

let clickHandler: (e: Event) => void = () => {};

export default <Directive<HTMLElement, () => void>>{
  mounted(el, { value: callback }) {
    if (!callback) return;

    clickHandler = (e: Event) => {
      const target = e.target as HTMLElement;

      if (!el.contains(target)) {
        callback();
      }
    };

    document.addEventListener('click', clickHandler, { passive: true });
  },

  unmounted() {
    document.removeEventListener('click', clickHandler);
  },
}
