const isProdEnv = process.env.NUXT_SITE_ENV === 'production';
const DEV_ONLY_LOCALES = ['pt', 'it'];

export const I18N_LOCALES = [
  { name: 'English', code: 'en', iso: 'en-US', file: 'en.json' },
  { name: 'German', code: 'de', iso: 'de-DE', file: 'de.json' },
  { name: 'French', code: 'fr', iso: 'fr-FR', file: 'fr.json' },
  { name: 'Spanish', code: 'es', iso: 'es-ES', file: 'es.json' },
  { name: 'Portuguese', code: 'pt', iso: 'pt-PT', file: 'pt.json' },
  { name: 'Italian', code: 'it', iso: 'it-IT', file: 'it.json' }
].filter(
  ({ code }) => {
    if (!isProdEnv) return true;

    return !DEV_ONLY_LOCALES.includes(code);
  }
);

export const SUPPORTED_LOCALES = I18N_LOCALES.map(({ code }) => code);
export const UNSUPPORTED_LOCALES = isProdEnv ? DEV_ONLY_LOCALES : [];
