import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgFile, LazySvgGear, LazySvgInstagram, LazySvgX, LazySvgArrowBackward, LazySvgArrowForward, LazySvgArrowRightUp, LazySvgBestStorageForVeeam, LazySvgBurger, LazySvgCalendarToday, LazySvgCalendar, LazySvgChart, LazySvgCheck, LazySvgChevronDown, LazySvgChevronLeft, LazySvgChevronRight, LazySvgClose, LazySvgContents, LazySvgDownloadBox, LazySvgDownload, LazySvgEye, LazySvgHeart, LazySvgHome, LazySvgImmutability, LazySvgLikeColorfull, LazySvgLike, LazySvgLinkedIn, LazySvgLocation, LazySvgLockColorfull, LazySvgLock, LazySvgLogoHelp, LazySvgLogo, LazySvgOnPermToCloud, LazySvgPlanet, LazySvgPlayArrow, LazySvgRocketColorfull, LazySvgRocket, LazySvgScalability, LazySvgSearch, LazySvgStorageDisks, LazySvgStorage, LazySvgUpToDate, LazySvgYoutube, LazySvgZoomClose, LazySvgZoomIn } from '#components'
const lazyGlobalComponents = [
  ["SvgFile", LazySvgFile],
["SvgGear", LazySvgGear],
["SvgInstagram", LazySvgInstagram],
["SvgX", LazySvgX],
["SvgArrowBackward", LazySvgArrowBackward],
["SvgArrowForward", LazySvgArrowForward],
["SvgArrowRightUp", LazySvgArrowRightUp],
["SvgBestStorageForVeeam", LazySvgBestStorageForVeeam],
["SvgBurger", LazySvgBurger],
["SvgCalendarToday", LazySvgCalendarToday],
["SvgCalendar", LazySvgCalendar],
["SvgChart", LazySvgChart],
["SvgCheck", LazySvgCheck],
["SvgChevronDown", LazySvgChevronDown],
["SvgChevronLeft", LazySvgChevronLeft],
["SvgChevronRight", LazySvgChevronRight],
["SvgClose", LazySvgClose],
["SvgContents", LazySvgContents],
["SvgDownloadBox", LazySvgDownloadBox],
["SvgDownload", LazySvgDownload],
["SvgEye", LazySvgEye],
["SvgHeart", LazySvgHeart],
["SvgHome", LazySvgHome],
["SvgImmutability", LazySvgImmutability],
["SvgLikeColorfull", LazySvgLikeColorfull],
["SvgLike", LazySvgLike],
["SvgLinkedIn", LazySvgLinkedIn],
["SvgLocation", LazySvgLocation],
["SvgLockColorfull", LazySvgLockColorfull],
["SvgLock", LazySvgLock],
["SvgLogoHelp", LazySvgLogoHelp],
["SvgLogo", LazySvgLogo],
["SvgOnPermToCloud", LazySvgOnPermToCloud],
["SvgPlanet", LazySvgPlanet],
["SvgPlayArrow", LazySvgPlayArrow],
["SvgRocketColorfull", LazySvgRocketColorfull],
["SvgRocket", LazySvgRocket],
["SvgScalability", LazySvgScalability],
["SvgSearch", LazySvgSearch],
["SvgStorageDisks", LazySvgStorageDisks],
["SvgStorage", LazySvgStorage],
["SvgUpToDate", LazySvgUpToDate],
["SvgYoutube", LazySvgYoutube],
["SvgZoomClose", LazySvgZoomClose],
["SvgZoomIn", LazySvgZoomIn],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
