export const SUBSCRIBE = '/subscribe';
export const REPORT = '/report';
export const ACTIVITY = '/activity';

export const DEMO = '/demo';
export const DEMO_SHORT = '/demo-short';
export const BECOME_PARTNER = '/become-partner';
export const CONNECT_CHANNEL_MANAGER = '/connect-channel-manager';
export const WHITE_PAPER = '/white-paper';
export const VSA = '/vsa';
