import Cookie from '~/components/services/Cookie';
import isValidURL from '~/utils/isValidURL';

const ENTRY_PAGE_KEY = 'EntryPage';
const REFERER_PAGE_KEY = 'RefererPage';

const getOrigin = (value: string) => new URL(value).origin;

export default defineNuxtRouteMiddleware(() => {
  if (process.server) return;

  const savedRefererPage = Cookie.get(REFERER_PAGE_KEY) || '';
  const {
    referrer: newRefererPage,
    location: {
      href: newEntryPage,
    },
  } = document;

  if (
    [savedRefererPage, newRefererPage].every(isValidURL) &&
    getOrigin(savedRefererPage) === getOrigin(newRefererPage)
  ) return;

  Cookie.set(ENTRY_PAGE_KEY, newEntryPage);
  Cookie.set(REFERER_PAGE_KEY, newRefererPage);
});
