import { ofetch } from 'ofetch';
import getUserId from '~/utils/getUserId';
import { IpInfoCookieComponent } from '~/components/services/IpInfoCookieComponent';

type Headers = HeadersInit & {
  Locale: string
  user_id: string
  user_ip: string | null;
};

export default defineNuxtPlugin(() => {
  const nuxt = useNuxtApp();
  const i18nLocale = nuxt.$i18n.locale;

  const { getIpFromCookie } = IpInfoCookieComponent();

  globalThis.$fetch = ofetch.create({
    onRequest({ options }) {
      options.headers ??= {};
      const typedHeaders = <Headers>options.headers;

      typedHeaders.Locale = i18nLocale.value || 'en';

      if (process.client) {
        typedHeaders.user_id = getUserId();
        typedHeaders.user_ip = getIpFromCookie();
      }
    },
  });
});
