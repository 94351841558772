<template>
  <a data-component-name="LinkDSAR" href="https://my.datasubject.com/16CY2jU8Uv83w1BCJ/36785" target="_blank"
    class="nowrap">
    DSAR Form
  </a>
</template>

<style scoped lang="scss">
@import "$/mixins/typography";
@import "$/functions/token";

[data-component-name="LinkDSAR"] {
  color: token('link-reversed');
  @include link-text-3;

  &:hover {
    background-color: rgba(token('link-reversed'), 16%);
  }
}
</style>
