import Cookie from "~/components/services/Cookie";
import type { IpInfo } from "~/types/api-responses";

const IP_INFO_KEY = "ip_info";

export const IpInfoCookieComponent = () => {
  const getIpInfoCookie = () => Cookie.get(IP_INFO_KEY);

  const setIpInfoCookie = (data: IpInfo) => {
    const oneWeekFromNow = new Date();
    oneWeekFromNow.setTime(oneWeekFromNow.getTime() + 7 * 24 * 60 * 60 * 1000);

    Cookie.set(IP_INFO_KEY, JSON.stringify(data), {
      expires: oneWeekFromNow,
    });
  };

  const getIpFromCookie = () => {
    const cookieData = getIpInfoCookie();
    if (!cookieData) return null;
  
    try {
      const { ip } = JSON.parse(cookieData);
      return ip;
    } catch (error) {
      console.error('Error parsing IP info cookie:', error);
      return null;
    }
  };

  return {
    getIpInfoCookie,
    setIpInfoCookie,
    getIpFromCookie,
  };
};
