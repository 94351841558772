<template>
  <ul data-component-name="SocialLinks">
    <li
      v-for="item of ITEMS"
      :key="item.icon"
    >
      <ButtonLink
        :accent
        :fill
        :to="item.link"
        target="_blank"
      >
        <component :is="item.icon" />
      </ButtonLink>
    </li>
  </ul>
</template>

<script lang="ts">
import SvgLinkedIn from '~/components/redesign/svg/LinkedIn.vue';
import SvgX from '~/components/redesign/svg/X.vue';
import SvgYoutube from '~/components/redesign/svg/Youtube.vue';
import SvgInstagram from '~/components/redesign/svg/Instagram.vue';

export default {
  components: {
    SvgLinkedIn,
    SvgX,
    SvgYoutube,
    SvgInstagram,
  },
};
</script>

<script setup lang="ts">
// components
import ButtonLink from '~/components/redesign/ButtonLink.vue';

// constants
import LINKS from '~/constants/links';

// types
import type { StatesWrapperProps } from '~/components/redesign/StatesWrapper.types';

withDefaults(
  defineProps<Partial<Omit<StatesWrapperProps, 'disabled'>>>(),
  {
    fill: 'outline',
    accent: 'white',
  },
);

const ITEMS = [
  { icon: 'SvgLinkedIn',  link: LINKS.OBJECT_FIRST.LINKED_IN },
  { icon: 'SvgX',         link: LINKS.OBJECT_FIRST.X },
  { icon: 'SvgYoutube',   link: LINKS.OBJECT_FIRST.YOUTUBE },
  { icon: 'SvgInstagram', link: LINKS.OBJECT_FIRST.INSTAGRAM },
];
</script>

<style scoped lang="scss">
@import "$/mixins/flex";
@import "$/mixins/common";
@import "$/functions/token";

ul[data-component-name="SocialLinks"] {
  @include flex-center-sb;
  gap: 0 1rem;

  li {
    a {
      svg {
        @include fixed-size(1.5rem);
      }
    }
  }
}
</style>
