<template>
  <div data-page-name="error">
    <TheHeader>
      <template #right>
        <button
          type="button"
          aria-label="open menu"
          @click="toggleMobileMenu(true)"
        >
          <SvgBurger />
        </button>
      </template>
    </TheHeader>

    <BreakingNewsBanner v-if="isPageMayHaveBanner">
      <NuxtLink :to="localePath('/welcome-192tb-ootbi/website-banner/?utm_source=website-banner')">
        {{ $t('BreakingNewsBanner') }}
      </NuxtLink>
    </BreakingNewsBanner>

    <AppOverlay :visible="isMobileMenuOpened">
      <SidebarMobile
        :class="{ active: isMobileMenuOpened }"
        @close="toggleMobileMenu(false)"
      />
    </AppOverlay>

    <main>
      <section>
        <AppWrapper>
          <div class="left">
            <h1>{{ $t('Got_Veeam') }}?</h1>
            <p>{{ $t('error.description') }}</p>

            <ButtonLink :to="localePathWithSlash('/')">
              {{ $t('Visit_Home_Page') }}
              <SvgArrowForward />
            </ButtonLink>
          </div>

          <div class="right">
            <NuxtImg
              src="/redesign/images/ootbi-cartoon-404.webp"
              alt="Object First cartoon character with magnifying glass between two numbers four"

              width="697"
              height="417"

              sizes="xs:328px md:697px"
              densities="x1 x2"
              format="webp"
            />
          </div>
        </AppWrapper>
      </section>
    </main>

    <TheFooter />
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';

// components
import TheHeader from '~/components/redesign/TheHeader.vue';
import BreakingNewsBanner from '~/components/redesign/BreakingNewsBanner.vue';
import AppOverlay from '~/components/redesign/AppOverlay.vue';
import SidebarMobile from '~/components/redesign/SidebarMobile.vue';
import AppWrapper from '~/components/redesign/AppWrapper.vue';
import ButtonLink from '~/components/redesign/ButtonLink.vue';
import TheFooter from '~/components/redesign/TheFooter.vue';

// composable
import { storeToRefs } from "pinia";
import { useLocalePath } from "#i18n";
import { useVeeamOnStore } from "~/stores/veeamon";

// constants
import { REPORT } from '~/constants/api-endpoints';

const { error } = defineProps<{ error: NuxtError }>();

const localePath = useLocalePath();
const localePathWithSlash = useLocalePathWithSlash();
const { public: { env, api_v2: baseURL } } = useRuntimeConfig();

const { isPageMayHaveBanner } = storeToRefs(useVeeamOnStore());

const isMobileMenuOpened = ref(false);
const toggleMobileMenu = (value: boolean) => {
  isMobileMenuOpened.value = value;
};

useHead({
  bodyAttrs: {
    class: {
      'no-scroll': isMobileMenuOpened,
    },
  },
});

if (['production', 'development'].includes(env)) {
  const copy = <T extends {}>(obj: T): T => Object
    .getOwnPropertyNames(obj)
    .reduce((acc, key) => {
      acc[key] = obj[key];

      return acc;
    }, {} as T);

  const body = { ...copy(error), env, url: document.location.href };

  if (error.cause) {
    body.cause = copy(error.cause);
  }

  $fetch(REPORT, { method: 'POST', baseURL, body });
}
</script>

<style scoped lang="scss">
@import "$/mixins/typography";
@import "$/mixins/flex";
@import "$/mixins/media";
@import "$/functions/token";

[data-page-name="error"] {
  padding-top: 88px;

  &:has([data-component-name="BreakingNewsBanner"]) {
    padding-top: calc(88px + 52px);

    @include mobile {
      padding-top: calc(60px + 52px);
    }
  }

  [data-component-name="TheHeader"] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;

    button {
      display: none;

      @include tablet {
        display: block;
      }
    }
  }

  main {
    padding: 8rem 0;

    @include tablet {
      padding: 5rem 0 8rem 0;
    }

    @include mobile {
      padding: 2.5rem 0 5rem 0;
    }

    section {
      @include flex-start-sb;
      gap: 0 2rem;

      @include tablet {
        flex-direction: column;
        gap: 1.5rem 0;
      }

      @include mobile {
        gap: 2.5rem 0;
      }

      .left {
        padding-top: 6.94rem;

        @include tablet {
          padding-top: 0;
        }

        @include mobile {
          width: 100%;
        }

        h1 {
          @include h2;
          margin-bottom: 1rem;

          @include mobile {
            margin-bottom: 0.5rem;
          }
        }

        p {
          @include body-2;
          margin-bottom: 2rem;

          @include mobile {
            margin-bottom: 1.5rem;
          }
        }

        a {
          display: block;
          width: max-content;

          @include mobile {
            width: 100%;
          }
        }
      }

      .right {
        margin: 0 auto;

        @include mobile {
          width: 100%;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  [data-component-name="AppOverlay"] {
    [data-component-name="SidebarMobile"] {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;

      transform: translateX(100%);
      transition: transform 0.15s ease-in-out;

      &.active {
        transform: translateX(0);
      }
    }
  }
}
</style>
