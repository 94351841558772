import getPathPieces from './getPathPieces';

// constants
import { SUPPORTED_LOCALES, UNSUPPORTED_LOCALES } from '~/constants';

export default function hasLocaleInPath(path: string) {
  const [maybeLocale] = getPathPieces(path);

  return [
    ...SUPPORTED_LOCALES,
    ...UNSUPPORTED_LOCALES,
  ].includes(maybeLocale);
}
