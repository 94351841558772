<template>
  <NuxtLink
    data-component-name="LinkPrivacyNotice"
    :to="localePathWithSlash(PATHS.PRIVACY_POLICY.path)"
    target="_blank"
    class="nowrap"
  >
    {{ $t(PATHS.PRIVACY_POLICY.i18nKey) }}
  </NuxtLink>
</template>

<script setup lang="ts">
import { PATHS } from '~/constants/nav-items';
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

const localePathWithSlash = useLocalePathWithSlash();
</script>

<style scoped lang="scss">
@import "$/mixins/typography";
@import "$/functions/token";

[data-component-name="LinkPrivacyNotice"] {
  color: token('link');
  @include caption;
  text-decoration: underline;

  transition: background-color 0.15s ease;

  &:hover {
    background-color: rgba(token('link'), 16%);
  }

  &:active {
    background-color: rgba(token('link'), 32%);
  }
}
</style>
